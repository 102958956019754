import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';

const sideMenuStyles ={
    justifyContent: 'space-between',
    padding: '10px',
    background: "#00596f"
}

const sideMenuListStyle={
    fontSize:"20px",
    color:"#00596f",
    background:"#FFF",
    width: "100%",
    display:"block",
    padding:"10px",
    marginTop: "10px",
    borderRadius: "2px"
}


class SideMenuAdmin extends Component {
    render() {
        let sideMenuLinks = <p>Somting went wrong</p>
        if(this.props.awardLevel === "Rising Star"){
            sideMenuLinks =
                    <ul>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/review/topic1/${this.props.org}`}>Topic 1</NavLink></li>
                    </ul>
        }else if (this.props.awardLevel === "Bronze Level 1" || this.props.awardLevel === "Bronze Level 2"){
            sideMenuLinks = 
                    <ul>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/review/topic1/${this.props.org}`}>Topic 1</NavLink></li>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/additional-documents-review/${this.props.org}`}>Additional Documents</NavLink></li>
                    </ul>
        }else if(this.props.awardLevel === "Silver Level 1" || this.props.awardLevel === "Silver Level 2"){
            sideMenuLinks = 
                    <ul>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/review/topic1/${this.props.org}`}>Topic 1</NavLink></li>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/review/topic2/${this.props.org}`}>Topic 2</NavLink></li>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/additional-documents-review/${this.props.org}`}>Additional Documents</NavLink></li>
                    </ul>
        }else{
            sideMenuLinks = 
                    <ul>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/review/topic1/${this.props.org}`}>Topic 1</NavLink></li>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/review/topic2/${this.props.org}`}>Topic 2</NavLink></li>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/review/topic3/${this.props.org}`}>Topic 3</NavLink></li>
                        <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/additional-documents-review/${this.props.org}`}>Additional Documents</NavLink></li>
                    </ul>
        }

        return (
            <div className="bp3-card"  style={sideMenuStyles}>
                <div className="award-type-title"><h1 style={{color: "#FFF", textAlign: "center"}} >{this.props.awardType}</h1></div>
                <div className="award-level-title"><h2 style={{color: "#FFF", textAlign: "center"}} >{this.props.awardLevel}</h2></div>

                {sideMenuLinks}
            </div>
        );
    }
}

export default SideMenuAdmin;
