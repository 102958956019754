import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {base} from '../hoc/Base';

class ReviewerLink extends Component{
    constructor(props) {
        super(props);
        this.state = {
            roll: '',
        }
    }

    componentDidMount() {
        base.fetch('admins',{
            context:this,
            asArray:true,
        }).then(data => {
            this.setState({roll:'reviewer'})
        }).catch(error => {
            this.setState({roll:''})
        })
    }

    render(){
        return(
            <div>
                {!!this.state.roll ? 
                <div>
                    <Link className="bp3-button bp3-minimal" to="/reviewer">Review</Link>
                    <span className="bp3-navbar-divider"></span>
                </div>
                : null}
            </div>
        )
    }
}

export default ReviewerLink;