import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import TopContainer from '../hoc/TopContainer';
import { Dialog, Collapse, Elevation, Card } from '@blueprintjs/core';

import medal from '../assets/images/medal.png';
import platinum from '../assets/images/platinum.png';
import diamond from '../assets/images/diamond.png';
import star from '../assets/images/star.png';

import { base} from '../hoc/Base';

import * as YEAR from '../constants/year';

const gordAwardLevelStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
}

const gordLevelButton = {
    maxWidth: "100%",
    flexGrow: "1",
    margin: "5px",
    textAlign: "center",
    color:"#FFFFFF",
}

class AwardGSLevel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            awardLevels: [
                { id: 1, level: 'Rising Star', color: 'diamond', icon: star },
                { id: 2, level: 'Bronze Level', color: 'bronze', icon: medal },
                { id: 3, level: 'Silver Level', color: 'silver', icon: medal },
                { id: 4, level: 'Gold Level', color: 'gold', icon: medal },
                { id: 5, level: 'Platinum', color: 'platinum', icon: platinum },
                { id: 6, level: 'Diamond', color: 'diamond', icon: diamond }
            ],
            isOpen:false,
            matrixOpen:true,
        }
    }

    //delete documents
    deleteInput = (user) =>{
        base.remove(`awards/${user}/${YEAR.CURRENT_YEAR}/GordSmith/awardLevel`).then(()=> {
        })
        base.remove(`awards/${user}/${YEAR.CURRENT_YEAR}/GordSmith/topic1`).then(()=> {
        })
        base.remove(`awards/${user}/${YEAR.CURRENT_YEAR}/GordSmith/topic2`).then(()=> {
        })
        base.remove(`awards/${user}/${YEAR.CURRENT_YEAR}/GordSmith/topic3`).then(()=> {
        })
        base.remove(`awards/${user}/${YEAR.CURRENT_YEAR}/GordSmith/additionalDocument`).then(()=> {
        })
        this.setState({
            isOpen:false,
        })
    }

       //toggle dialog box off
    toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });

    handleClick = () => this.setState({ matrixOpen: !this.state.matrixOpen });

    render() {
        //check if awardlevel is not set,  display all buttons
        return (
            <TopContainer>
                <Dialog
                    isOpen={this.state.isOpen}
                    onClose={this.toggleDialog}
                    title="Reset Application">

                    <div className="bp3-dialog-body">
                        
                        <p> By resetting the Gord Smith Application all form inputs will be deleted.  Do you wish to continue?</p>
                        <div>
                            <button onClick={() => this.deleteInput(this.props.user)} type="button" className="bp3-button bp3-intent-danger">
                            Yes reset me
                            </button>
                        </div>
                    </div>
                </Dialog>

                <h1 style={{ textAlign: "center" }}>GORD SMITH HEALTHY WORKPLACE AWARD</h1>
                <h3 style={{ textAlign: "center" }}>Please select the award level that you are applying for.</h3>
                <p style={{ textAlign: "center", color: "#FFFFFF" }}> If you have already selected you award level, click on the highlighted box to access your application</p>
        
               {(this.props.awardLevel === undefined) ?
                    <div>
                        <div style={gordAwardLevelStyle}>
                            {this.state.awardLevels.map((level) => {
                                return (
                                    < Link to={`/application-form/topic1/${this.props.user}`}
                                        key={level.id}
                                        style={gordLevelButton}
                                        className={level.color + " bp3-card bp3-elevation-0 bp3-interactive"}
                                        onClick={() => this.props.addGSAwardLevel(level.level)}>
                                        <img src={level.icon} alt={level.level} />
                                        <h3>{level.level}</h3>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                    :
                    <div>
                        <div style={gordAwardLevelStyle}>
                            {this.state.awardLevels.map((level) => {
                                return (
                                    this.props.awardLevel === level.level
                                    ?
                                    < Link to={`/application-form/topic1/${this.props.user}`}
                                    key={level.id}
                                    style={gordLevelButton}
                                    className={level.color + " bp3-card bp3-elevation-0 bp3-interactive"}
                                    onClick={() => this.props.addGSAwardLevel(level.level)}>
                                            <img src={level.icon} alt={level.level} />
                                            <h3>{level.level}</h3>
                                        </Link>
                                        : <div style={gordLevelButton}
                                        key={level.id}
                                        className=" bp3-card bp3-elevation-0 disabledLink">
                                            <img src={level.icon} alt={level.level} />
                                            <h3>{level.level}</h3>
                                        </div>
                                    )
                                })}
                        </div>


                        <div style={{float:"right"}}>
                            <button onClick={this.toggleDialog} text="Show dialog"  type="button" className="bp3-button bp3-intent-danger">
                                Reset Application
                            </button>
                        </div>
                    </div>}

            <button onClick={this.handleClick} text="Level Matrix"  type="button" className="bp3-button bp3-intent-primary bp3-icon-chevron-down">
                    Level Descriptions 
                    </button>
                    
                    <Collapse isOpen={this.state.matrixOpen}>
                      <Card elvation={Elevation.TWO}>        
                        <table style={{width:"100%"}} className="bp3-html-table bp3-html-table-striped bp3-html-table-condensed">
                            <thead>
                                <tr>
                                    <th>Requirement</th>
                                    <th>Rising Star</th>
                                    <th>Bronze Level</th>
                                    <th>Silver Level</th>
                                    <th>Gold Level</th>
                                    <th>Platinum</th>
                                    <th>Diamond</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Number of Wellness Topics Required</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>3</td>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>Awareness Raising</td>
                                    <td>1 of the 3 activity options</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>6</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>Education and Skill Building</td>
                                    <td>1 of the 3 activity options</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>                                    
                                    <td>6</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>Environmental Support</td>
                                    <td>1 of the 3 activity options</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>6</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>Evaluation</td>
                                    <td>0</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>2</td>
                                    <td>2</td>
                                </tr>
                                <tr>
                                    <td>Supportive Policy</td>
                                    <td>0</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <td>Comprehensive Workplace Wellness Policy</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>Employer’s Commitment to Workplace Wellness</td>
                                    <td>1</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td>Organizational Social Responsibility</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>2</td>
                                </tr>
                                <tr>
                                    <td>Living Wage</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1</td>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td>2 consecutive years at the Platinum Level</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                        </table>
   <br/>

   <p><strong>Please Note:</strong> Awareness raising, education and skill building, environmental supports and supportive policies MUST be specific to the wellness topic(s) chosen. For more information on the requirements  refer to our <a href=" https://www.wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">website</a>, or email cdip@wechu.org.</p>

<p>Rising Star applicants are only required to submit one wellness activity that is either awareness raising, education/skill building or an environmental support.  Workplaces may only receive this level for two years.</p>

<p>These following pages on our website provide example awareness raising, education and skill building, environmental support and supportive policies for common wellness topics:</p>
                        <strong>
                            <ul>
                                <li><a href="https://www.wechu.org/workplace-health-programs/mental-health-promotion-workplace" target="_blank"  rel="noopener noreferrer" >Mental Health Promotion in the Workplace</a></li>
                                <li><a href="https://www.wechu.org/workplace-health-programs/promoting-healthy-eating-workplace" target="_blank"  rel="noopener noreferrer" >Promoting Healthy Eating in the Workplace</a></li>
                                <li><a href="https://www.wechu.org/workplace-health-programs/promoting-physical-activity-workplace" target="_blank"  rel="noopener noreferrer" >Promoting Physical Activity in the Workplace</a></li>
                                <li><a href="https://www.wechu.org/workplace-health-programs/preventing-injury-workplace" target="_blank"  rel="noopener noreferrer" >Preventing Injury in the Workplace</a></li>
                                <li><a href="https://www.wechu.org/workplace-health-programs/infection-control-and-prevention-workplace" target="_blank"  rel="noopener noreferrer" >Infection Control and Prevention in the Workplace</a></li>
                                <li><a href="https://www.wechu.org/workplace-health-programs/substance-use-resources-workplace" target="_blank"  rel="noopener noreferrer" >Substance Use Resources for the Workplace</a></li>
                            </ul>
                        </strong>

                        <p><strong>Requirement Definitions</strong></p>
                        <table style={{width:"100%"}} className="bp3-html-table bp3-html-table-striped bp3-html-table-condensed">
                            <tbody>
                                <tr>
                                    <td><strong>Awareness Raising Activities</strong></td>
                                    <td><p>Awareness raising activities increase awareness about important wellness issues through effective communication strategies that reach all employees. Examples of awareness raising activities include postings on bulletin boards, e-mail blasts, or health newsletters. For more examples refer to our <a href="https://www.wechu.org/professionals/workplace-health-programs" target="_blank"  rel="noopener noreferrer">website</a>.</p></td>
                                </tr>

                                <tr>
                                    <td><strong>Education and Skill Building </strong></td>
                                    <td><p>Education and skill building activities help employees develop the knowledge and skills necessary to support healthy living. These types of activities include active learning and/or hands-on activities. Examples include lunch and learn presentations, workshops, challenges, and other skill building activities. For more examples  refer to our <a href="https://www.wechu.org/professionals/workplace-health-programs" target="_blank"  rel="noopener noreferrer">website</a>.</p> </td>
                                </tr>

                                <tr>
                                    <td><strong>Environmental Supports </strong></td>
                                    <td><p>Environmental supports create a physical, social, and cultural workplace environment that encourages and supports employees in making healthier choices. Examples of environmental supports include having healthy food choices available in cafeterias and/or vending machines, or allowing employees to have flexible work schedules. For more examples refer to our <a href="https://www.wechu.org/professionals/workplace-health-programs" target="_blank"  rel="noopener noreferrer">website</a>.</p></td>
                                </tr>

                                <tr>
                                    <td><strong>Evaluation </strong></td>
                                    <td><p>A workplace wellness program evaluation identifies how effective your program was at improving the wellbeing of your employees. It can also tell you how effective you were in getting the word out to your employees, how interested your employees were in the activities you offered, and help you plan for future workplace wellness initiatives. For examples of evaluation questions refer to our <a href="https://www.wechu.org/workplace-health-programs/workplace-wellness-planning-and-evaluation-resources" target="_blank"  rel="noopener noreferrer">website</a>. </p></td>
                                </tr>

                                <tr>
                                    <td><strong>Supportive Policies </strong> </td>
                                    <td><p>Supportive policies are policies that guide and support employees in making healthy decisions in relation to a specific health topic. Examples of supportive policies include healthy meetings policies, job sharing policies, and professional development policies. For examples refer to our <a href="https://www.wechu.org/professionals/workplace-health-programs" target="_blank"  rel="noopener noreferrer">website</a>.</p>

                                    </td>
                                </tr>

                                <tr>
                                    <td><strong>Comprehensive Workplace Wellness Policy</strong></td>
                                    <td><p>A comprehensive workplace wellness policy is the foundation of a comprehensive workplace wellness program. It specifies the organizational commitment to providing employees with a comprehensive workplace wellness program, outlines responsibilities regarding wellness programming, and conveys how the wellness program is to be offered to employees and their families. For a sample comprehensive workplace wellness policy refer to our <a href="https://www.wechu.org/workplace-health-programs/gord-smith-healthy-workplace-awards-criteria" target="_blank"  rel="noopener noreferrer">website</a> and click on the second drop down question</p></td>
                                </tr>

                                <tr>
                                    <td><strong>Employer’s Commitment to Employee Wellness</strong></td>
                                    <td><p>This requirement only applies to the Rising Star Award.  An example statement of an Employer’s Commitment to Employee Wellness: (insert company name) is committed to supporting the health and well-being of its employees.  (insert company name) will achieve this by planning activities that promote an awareness of a health topic, or educate employees about health living, or foster a supportive environment that makes the healthy choice the easier choice for employees.</p></td>
                                </tr>

                                <tr>
                                    <td><strong>Organizational Social Responsibility</strong></td>
                                    <td><p>Organizational social responsibility activities are activities that demonstrate your organization’s contribution to improving the health and well-being of the community. Examples of organizational social responsibility activities include supporting local children’s sports teams, planting trees for a cause, having a blood donation campaign, or supporting a local student nutrition program. For more examples  refer to our <a href="https://www.wechu.org/workplace-health-programs/gord-smith-healthy-workplace-awards-criteria" target="_blank"  rel="noopener noreferrer">website</a> and click on the second drop down question.</p> </td>
                                </tr>

                                <tr>
                                    <td><strong>Living Wage Certification for Employers</strong></td>
                                    <td><p>A living wage is different from the minimum wage. The living wage is based on the real cost of goods and services in Windsor and Essex County. A living wage is based on the idea that every person has the right to enjoy good health, relaxation, culture, and fun. It provides the means to raise children and to take part in social and community activities. It also allows families to reduce the stress of living in poverty. To become a Diamond or Platinum level award winner, you must be a Living Wage Employer. Visit <a href="https://www.wechu.org/living-wage" target="_blank"  rel="noopener noreferrer">www.wechu.org/living-wage</a> to apply. Applications are typically reviewed and a decision is provided within 10 days of applying.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </Card>
                    </Collapse>
            </TopContainer>
    )
}
}

export default AwardGSLevel;