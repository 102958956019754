import React, { Component } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login/Login';
import Logout from './components/Login/Logout';

import ApplicationFormBF from './containers/ApplicationFormBF';
import AwardType from './containers/AwardType';
import AwardGSLevel from './containers/AwardGSLevel';
import AwardBFLevel from './containers/AwardBFLevel';
import Awards from './containers/Awards';
import ApplicaitonForm from './containers/ApplicationForm';
import ReviewForm from './containers/ReviewForm';
import AdditionalDocuments from './containers/AdditoinalDocuments';
import AdditionalDocumentsReview from './containers/AdditionalDocumentsReview';
import ReviewBF from './containers/ReviewBF';
import Reviewer from './containers/Reviewer';
import Submit from './containers/Submit';
import Index from './containers/Index';
import Account from './containers/Account';

import { BrowserRouter as Router, Route} from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';

import TopContainer from './hoc/TopContainer';
import { base, app } from './hoc/Base';
import {AuthProvider} from './hoc/Auth';
import PrivateRoute from './hoc/PrivateRoute';
import * as YEAR from './constants/year';
import './App.css';

class App extends Component {
  constructor() {
    super();
    this.setCurrentUser = this.setCurrentUser.bind(this);
    this.addGSAwardType = this.addGSAwardType.bind(this);
    this.addBFAwardType = this.addBFAwardType.bind(this);
    this.addGSAwardLevel = this.addGSAwardLevel.bind(this);
    this.addBFAwardLevel = this.addBFAwardLevel.bind(this);
    this.addFormData = this.addFormData.bind(this);
    this.addFile = this.addFile.bind(this);
    this.state = {
      loading: true,
      currentUser: null,
      awardGSData:{},
      awardBFData: {},
    }
  }

  //set current user and make authenticated
  setCurrentUser(user) {
    if (user) {
      this.setState({
        currentUser: user,
      })
    } else {
      this.setState({
        currentUser: null,
      })
    }
  }

  /****************************************
              Progress
   ******************************************/
  addGSProgress = (progress)=> {
    this.setState({
      awardGSData: { progress: progress},
      progress
    })
  }

  addBFProgress = (progress)=>{
    this.setState({
      awardBFData:{progress:progress},
      progress
    })
  }

  /***************************************
              Gord Smith
  *****************************************/
  //add award type to gord smith state push to database
  addGSAwardType(awardType) {
    this.setState({
      awardGSData: { awardType: awardType },
      awardType
    })
  }
  //add award level to gord smith state and push to database
  addGSAwardLevel(awardLevel) {
    let GSRef = this.state.awardGSData;

    this.setState({
      awardGSData: { ...GSRef, awardLevel },
      awardLevel
    })
  }

  /***************************************
                Bike Friendly
    *****************************************/
  //add award type to bike friendly state
  addBFAwardType(awardType) {
    this.setState({
      awardBFData: {
        awardType: awardType
      },
      awardType: awardType
    })
  }
  //add award level to bike friendly state and push to database
  addBFAwardLevel(awardLevel) {
    let BFRef = this.state.awardBFData;
    this.setState({
      awardBFData: { ...BFRef, awardLevel },
      awardLevel
    })
  }

  /***************************************
                Add form data
    *****************************************/
  addFormData(topicData, topicLevel) {
    // determin what topic user is on
    switch(topicLevel){
      case 'topic1':
        const topic1 = topicData
        this.setState({
          awardGSData: { topic1 }
        })
      break;

      case 'topic2':
        const topic2 = topicData
        this.setState({
          awardGSData: { topic2 }
        })
      break;

      case 'topic3':
        const topic3 = topicData
        this.setState({
          awardGSData: { topic3 }
        })
      break;

      case 'additionalDocument':
        const additionalDocument = topicData
        this.setState({
          awardGSData: {additionalDocument}
        })
      break;

      case 'bike-friendly':
        const bikeFriendly = topicData
        this.setState({
          awardBFData: {bikeFriendly}
        })
      break;

      default:
        alert('oops something went wrong')
      
    }
  }

  /***************************************
               Add files
   *****************************************/
  addFile(file, topicLevel) {
    if (topicLevel === 'Topic 1') {
      const FilesForTopic1 = file
      this.setState({
        awardGSData: { FilesForTopic1 }
      })
    } else if (topicLevel === 'Topic 2') {
      const FilesForTopic2 = file
      this.setState({
        awardGSData: { FilesForTopic2 }
      })
    } else {
      const FilesForTopic3 = file
      this.setState({
        awardGSData: { FilesForTopic3 }
      })
    }
  }

  UNSAFE_componentWillMount() {
   app.auth().onAuthStateChanged((user) => {
      if (user) {
        base.fetch('awards/'+ user.uid, {
          context: this,
          asArray: false
        })
        .catch(error => {
          alert(error)
        })
        this.setState({
          loading: false,
          currentUser: user.uid
        })
        
        //Sync Gord Smith data
          this.awardsGSDataRef = base.syncState(`awards/${user.uid}/${YEAR.CURRENT_YEAR}/GordSmith`, {
            context: this,
            state: 'awardGSData'
          })
        
        //Bike Friendly add to database top level
          this.awardBFDataRef = base.syncState(`awards/${user.uid}/${YEAR.CURRENT_YEAR}/BikeFriendly`, {
            context: this,
            state: 'awardBFData'
          })
      } else {
        this.setState({
          loading: false,
          currentUser: ''
        })
      }
    })
  }

  componentDidMount() {
   app.auth().onAuthStateChanged((user) => {
      if (user) {
        
        //check if Gord Smith data exist, if not init database
        if (this.state.awardGSData.length === 0 ){
          base.post(`awards/${user.uid}/${YEAR.CURRENT_YEAR}/GordSmith`, {
            data:  {awardLevel: '', 
            awardType: '', 
            files: [''],
            topic1: [{ topic: '' },
                  { awareness1: ''},
                  { awareness1Dec: ''},
                  { awareness2: ''},
                  { awareness2Dec: ''},
                  { education1: '' },
                  { education1Dec: '' },
                  { education2: '' },
                  { education2Dec: '' },
                  { environmental1: '' },
                  { environmental1Dec: '' },
                  { environmental2: '' },
                  { environmental2Dec: '' },
                  { policy1: '' },
                  { policy2: '' },
                  { othertopic: ''},
                  { winnersBooklet: ''}],
            topic2: [{ topic: '' },
                  { awareness1: ''},
                  { awareness1Dec: ''},
                  { awareness2: ''},
                  { awareness2Dec: ''},
                  { education1: '' },
                  { education1Dec: '' },
                  { education2: '' },
                  { education2Dec: '' },
                  { environmental1: '' },
                  { environmental1Dec: '' },
                  { environmental2: '' },
                  { environmental2Dec: '' },
                  { policy1: '' },
                  { policy2: '' },],
            topic3: [{ topic: '' },
                  { awareness1: ''},
                  { awareness1Dec: ''},
                  { awareness2: ''},
                  { awareness2Dec: ''},
                  { education1: '' },
                  { education1Dec: '' },
                  { education2: '' },
                  { education2Dec: '' },
                  { environmental1: '' },
                  { environmental1Dec: '' },
                  { environmental2: '' },
                  { environmental2Dec: '' },
                  { policy1: '' },
                  { policy2: '' },]},
          })
        }

        //check if Bike Friendly data exist, if not init database
        if (this.state.awardBFData.length === 0 ){
          base.post(`awards/${user.uid}/${YEAR.CURRENT_YEAR}/BikeFriendly`, {
            data:  {awardLevel: '', 
            awardType: '', 
            files: [''],
            topic1: [{ topic: '' },
                  { awareness1: ''},
                  { awareness2: ''},
                  { education1: '' },
                  { education2: '' },
                  { environmental1: '' },
                  { environmental2: '' },
                  { policy1: '' },
                  { policy2: '' },],
            topic2: [{ topic: '' },
                  { awareness1: ''},
                  { awareness2: ''},
                  { education1: '' },
                  { education2: '' },
                  { environmental1: '' },
                  { environmental2: '' },
                  { policy1: '' },
                  { policy2: '' },],
            topic3: [{ topic: '' },
                  { awareness1: ''},
                  { awareness2: ''},
                  { education1: '' },
                  { education2: '' },
                  { environmental1: '' },
                  { environmental2: '' },
                  { policy1: '' },
                  { policy2: '' },]},
          })
        }
      }
    })
  }


  render() {
    if (this.state.loading === true) {
      return (
        <div style={{ textAlign: "center", position: "absolute", top: "25%", left: "50%" }}>
          <h3>Loading...</h3>
          <Spinner />
        </div>
      )
    }
    return (
      <div className="wrapper">
        <AuthProvider>
          <Router>
          <TopContainer>
            <Header />
            <div className="main-container">

              <Route exact path="/" component={Index} />
              <Route exact path="/login" component={Login}/>
              <Route exact path="/logout" component={Logout} />

              <PrivateRoute
                exact
                path="/account"
                user={this.state.currentUser}
                component={Account}/>

              <PrivateRoute
                exact 
                path="/awards" 
                user={this.state.currentUser}
                component={Awards} />
              
              <PrivateRoute
                exact
                path="/award-type"
                addGSAwardType={this.addGSAwardType}
                addBFAwardType={this.addBFAwardType}
                component={AwardType} />

              <PrivateRoute
                exact
                path="/award-GS-level"
                addGSAwardLevel={this.addGSAwardLevel}
                awardLevel={this.state.awardGSData.awardLevel}
                notClickable={this.state.notClickable}
                user={this.state.currentUser}
                component={AwardGSLevel} />

              <PrivateRoute
                exact
                path="/award-BF-level"
                awardLevel={this.state.awardBFData.awardLevel}
                addBFAwardLevel={this.addBFAwardLevel}
                user={this.state.currentUser}
                component={AwardBFLevel} />

              <PrivateRoute
                exact
                path="/application-form/topic1/:uid"
                topicLevel="topic1"
                awardType={this.state.awardGSData.awardType}
                awardLevel={this.state.awardGSData.awardLevel}
                addFormData={this.addFormData}
                addFile={this.addFile}
                user={this.state.currentUser}
                addGSProgress={this.addGSProgress}
                component={ApplicaitonForm} />

              <PrivateRoute
                exact
                path="/application-form/topic2/:uid"
                topicLevel="topic2"
                awardType={this.state.awardGSData.awardType}
                awardLevel={this.state.awardGSData.awardLevel}
                addFormData={this.addFormData}
                addFile={this.addFile}
                user={this.state.currentUser}
                addGSProgress={this.addGSProgress}
                component={ApplicaitonForm} />

              <PrivateRoute
                exact
                path="/application-form/topic3/:uid"
                topicLevel="topic3"
                awardType={this.state.awardGSData.awardType}
                awardLevel={this.state.awardGSData.awardLevel}
                addFormData={this.addFormData}
                addFile={this.addFile}
                user={this.state.currentUser}
                addGSProgress={this.addGSProgress}
                component={ApplicaitonForm} />

              <PrivateRoute
                exact
                path="/additional-documents/:uid"
                topicLevel="additionalDocument"
                awardType={this.state.awardGSData.awardType}
                awardLevel={this.state.awardGSData.awardLevel}
                user={this.state.currentUser}
                addFormData={this.addFormData}
                addGSProgress={this.addGSProgress}
                component={AdditionalDocuments} />

              <PrivateRoute
                exact
                path="/bf-application-form/:uid"
                topicLevel="bike-friendly"
                awardType={this.state.awardBFData.awardType}
                awardLevel={this.state.awardBFData.awardLevel}
                user={this.state.currentUser}
                addFormData={this.addFormData}
                addBFProgress={this.addBFProgress}
                component={ApplicationFormBF} />

           
              <PrivateRoute 
                exact 
                path="/reviewer" 
                component={Reviewer} 
              />
             

              <PrivateRoute
                exact
                path="/review/topic1/:uid"
                topicLevel="topic1"
                awardType={this.state.awardGSData.awardType}
                awardLevel={this.state.awardGSData.awardLevel}
                user={this.state.currentUser}
                component={ReviewForm} />

              <PrivateRoute
                exact
                path="/review/topic2/:uid"
                topicLevel="topic2"
                awardType={this.state.awardGSData.awardType}
                awardLevel={this.state.awardGSData.awardLevel}
                user={this.state.currentUser}
                component={ReviewForm} />

              <PrivateRoute
                exact
                path="/review/topic3/:uid"
                topicLevel="topic3"
                awardType={this.state.awardGSData.awardType}
                awardLevel={this.state.awardGSData.awardLevel}
                user={this.state.currentUser}
                component={ReviewForm} />

                <PrivateRoute
                exact
                path="/additional-documents-review/:uid"
                topicLevel="additionalDocument"
                awardType={this.state.awardGSData.awardType}
                awardLevel={this.state.awardGSData.awardLevel}
                user={this.state.currentUser}
                component={AdditionalDocumentsReview} />

                <PrivateRoute
                exact
                path="/BFReview/:uid"
                topicLevel="bike-friendly"
                awardType={this.state.awardBFData.awardType}
                awardLevel={this.state.awardBFData.awardLevel}
                user={this.state.currentUser}
                component={ReviewBF} />

                <PrivateRoute
                exact
                path="/submitted"
                topicLevel="bike-friendly"
                awardType={this.state.awardBFData.awardType}
                awardLevel={this.state.awardBFData.awardLevel}
                user={this.state.currentUser}
                component={Submit} />


            </div>
            <Footer />
          </TopContainer>
          </Router>
        </AuthProvider>
      </div>
    );
  }
}

export default App;
