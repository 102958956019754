import React, { Component } from 'react';

import { base } from '../../hoc/Base';
import TopContainer from '../../hoc/TopContainer';


class Notes extends Component {
    constructor(props) {
        super(props);
        this.state={
            notes:''
        }
    }

    componentWillMount(){
        base.fetch(`notes/${this.props.user}/reviewNotes/${this.props.topicLevel}/${this.props.panel}/notes`, {
            context: this,
            asArray: false
        }).then(data => {
            if(data.length > 0){
                this.setState({
                    notes: data
                })
            }else{
                this.setState({
                    notes:''
            })
        }
        }).catch(error => {
            alert(error)
        })
    }


    render() {
        return (
            <TopContainer>
                {this.state.notes !== '' ?
                <div className="bp3-card bp3-elevation-2" style={{background: "#00596f"}}>
                    <label className="bp3-label" style={{color: "#FFFFFF"}}>Review notes</label>
                        <div className="bp3-card">
                            <p>{this.state.notes}</p>
                        </div>
                        
                    </div>:null}
            </TopContainer>

        )
    }
};

export default Notes;