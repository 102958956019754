import React, { Component } from 'react';
import TopContainer from '../hoc/TopContainer';
import { Link } from 'react-router-dom';

const awardtypeStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "stretch"
}

const awardtypeBtnStyle = {
    maxWidth: "100%",
    flex: "1",
    margin: "10px",
    textAlign: "center",
}

const paragraphStyle ={
    textAlign: "left", 
    textDecoration: "none", 
    marginTop: "28px", 
    fontSize: "18px", 
}

class AwardType extends Component {
    render() {
        return (
            <div className="award-type">
                <TopContainer>
                    <div style={awardtypeStyle}>
                        < div style={awardtypeBtnStyle} className=" bp3-card bp3-elevation-0 bp3-interactive">
                            <Link
                                to="/award-GS-level"
                                onClick={() => this.props.addGSAwardType("Gord Smith")}>
                                <h1>GORD SMITH HEALTHY WORKPLACE AWARD</h1>
                                <p style={paragraphStyle} >
                                The purpose of the awards program is to recognize local workplaces that provide comprehensive workplace wellness programming. The awards program is available to all workplaces in Windsor and Essex County, regardless of the company size or how established the workplace wellness program may be. Award recipients must demonstrate a strong commitment towards improving health by promoting and supporting a comprehensive approach to wellness programming for employees, and ideally, for family members and retirees.</p>
                            </Link>
                        </div>

                        {/* <div style={awardtypeBtnStyle} className=" bp3-card bp3-elevation-0 bp3-interactive">
                            <Link
                                to="/award-BF-level"
                                onClick={() => this.props.addBFAwardType("Bike Friendly")}>
                                <h1>BIKE FRIENDLY <br/>WORKPLACE AWARD</h1>
                                <p style={paragraphStyle}>A Bike Friendly Workplace Award can be awarded to any Windsor and Essex County Workplace that supports employees using their bicycle as a form of active transportation. A Bike Friendly Workplace Award is an excellent way for an organization to be recognized for their commitment to employee well-being and overall community health.</p>
                            </Link>
                        </div> */}
                    </div>
                </TopContainer>
            </div>
        );
    }
}

export default AwardType;
