import React, { Component } from 'react';

import { base, firebase } from '../../../hoc/Base';
import * as YEAR from '../../../constants/year';

class FileDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            fileData: [],
            fileDownload:[],
        }
    }

    componentDidMount() {
        if(this.props.awardType === 'BikeFriendly'){
            base.fetch(`awards/${this.props.org}/${YEAR.CURRENT_YEAR}/BikeFriendly/files/bike-friendly/${this.props.BFuploadPane}`, {
                context: this,
                asArray: true
            }).then(data=>{
                let fileData = data;
                
                const ref = firebase.storage().ref();
                let fileName = fileData;
    
                for (let value of fileName){
                    const FileRef = ref.child(`${this.props.org}/${value}`);
    
                    FileRef.getDownloadURL()
                    .then(url =>{
                        let downloalFileData = [{fileName: value, URL:url}];
                        this.setState(prevState => ({
                            fileDownload: [...prevState.fileDownload, downloalFileData]
                        }))
                    })
                }
            })
        }else{

        base.fetch(`awards/${this.props.org}/${YEAR.CURRENT_YEAR}/GordSmith/files/${this.props.topicLevel}/${this.props.uploadPane}`, {
            context: this,
            asArray: true
        }).then(data=>{
            let fileData = data;
            
            const ref = firebase.storage().ref();
            let fileName = fileData;

            for (let value of fileName){
                const FileRef = ref.child(`${this.props.org}/${YEAR.CURRENT_YEAR}/${value}`);

                FileRef.getDownloadURL()
                .then(url =>{
                    let downloalFileData = [{fileName: value, URL:url}];
                    this.setState(prevState => ({
                        fileDownload: [...prevState.fileDownload, downloalFileData]
                    }))
                })
            }
        })
    }
    }

    
    render() {
        return (
            <div className="bp3-card bp3-elevation-2">
                <ul style={{ marginTop: "0px" }}>
                    { this.state.fileDownload.map((file, index) => {
                        return (
                            <li style={{ textAlign: "left" }} key={index}>
                                <p style={{ height: "30px" }}>
                                   {file[0].fileName}
                                        <a target="_blank" rel="noopener noreferrer" className="right bp3-button bp3-intent-success bp3-icon-download align-right" href={file[0].URL}>download</a>
                                </p>

                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

};

export default FileDownload;