import React, { Component } from 'react';
import DownloadBtn from './DownloadBtn';
import * as YEAR from '../../../constants/year';

import { base, firebase } from '../../../hoc/Base';



class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this)
        this.removeFile = this.removeFile.bind(this)
        this.state = {
            file: null,
            fileData: [],
            year:''
        }
    }

    onChange(event) {
        event.preventDefault();
        this.setState({
            file: event.target.files[0]
        })
    }

    fileUpload(file) {
        //Create a storage ref
        const storageRef = firebase.storage().ref(this.props.user + "/" + YEAR.CURRENT_YEAR + "/" + file.name)
        //Upload file
        storageRef.put(file)
            .then(snapshot => {
                //console.log(snapshot);
                // console.log(file)
            });
        this.setState({
            file: null
        })

        //push file name to database
        base.push(`awards/${this.props.user}/${YEAR.CURRENT_YEAR}/${this.props.awardType}/files/${this.props.topicLevel}/${this.props.uploadPane}`, {
            data: file.name
        })

        this.fileUploadInput.value = ""
    }

    //remove file from storage and file name from database
    removeFile(index, file) {
        this.setState((prevState) => ({
            fileData: [...prevState.fileData.slice(0, index), ...prevState.fileData.slice(index + 1)]
        }))
        //delete file from db
        const firebaseStorageRef = firebase.storage().ref();
        const deleteFileRef = firebaseStorageRef.child(`${this.props.user}/${file}`)

        //delete file in storage
        deleteFileRef.delete()
    }


    componentDidMount() {
        base.syncState(`awards/${this.props.user}/${YEAR.CURRENT_YEAR}/${this.props.awardType}/files/${this.props.topicLevel}/${this.props.uploadPane}`, {
            context: this,
            state: 'fileData',
            asArray: true
        });
    }
    
    componentWillReceiveProps(prevProps){
        if(prevProps.year !== this.props.year){
            base.syncState(`awards/${this.props.user}/${this.props.awardType}/files/${this.props.topicLevel}/${this.props.uploadPane}`, {
                context: this,
                state: 'fileData',
                asArray: true
            });
        }

    }
    
    
    render() {
        return (
            <div className="bp3-card bp3-elevation-2">
                <ul style={{ marginTop: "0px" }}>
                    {this.state.fileData.map((file, index) => {
                        return (
                            <li style={{ textAlign: "left" }} key={index}>
                                <p style={{ height: "30px" }}>
                                   {file}
                                        <span
                                            className="bp3-button bp3-intent-danger bp3-icon-delete"
                                            style={{float:"right"}}
                                            onClick={() => this.removeFile(index, file)}> Delete</span>
                                        <DownloadBtn
                                            year={this.props.year}
                                            user={this.props.user}
                                            file={file}
                                        />
                                </p>
                            </li>
                        )
                    })}
                </ul>

                  
               <div className="file-upload">
                    <input
                        style={{ width: "50%" }}
                        type="file"
                        name="file"
                        ref={(input) => { this.fileUploadInput = input }}
                        onChange={this.onChange}
                        multiple />

                    {this.state.file !== null ?
                            <div
                                style={{ width: "140px", textAlign: "center" }}
                                role="button"
                                className="bp3-button bp3-intent-success bp3-align-right bp3-icon-cloud-upload"
                                aria-label="File Upload"
                                onClick={() => this.fileUpload(this.state.file)}>Upload File</div>
                        : null
                    }
                </div>
                
            
            </div>)
    }

};

export default FileUpload;