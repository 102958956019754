import React, {Component} from 'react';
import { Card } from '@blueprintjs/core';
import { base } from '../hoc/Base';


class AccountPage extends Component {
    constructor(props){
        super(props);
        this.state=({
            orgName:''
        })
    }

    componentDidMount(){
        base.fetch(`awards/${this.props.user}/orgName`,{
            context:this,
            asArray:true
        }).then(data => {
            this.setState({
                orgName: data
            })
        })
    }
    
    render(){
        return(
            <Card>
                <h1>Account</h1>
                <h2>Organization: {this.state.orgName}</h2>
                <h3>Application status</h3>
            </Card>
        )
    }
}

export default AccountPage;