import React, {useContext} from 'react';
import WECHU from '../assets/images/WECHU.png';
import {AuthContext} from "./../hoc/Auth";
import { Link } from 'react-router-dom';

import ReviewerLink from '../components/ReviewerLink';


const Header = () =>{
  //get current user from AuthContext
  const {currentUser} = useContext(AuthContext);
  return (
          <div className="header">
            <nav className="bp3-navbar bp3-dark">
              <div style={{margin:"0 auto", maxWidth:"1200px"}}>
                <div className="bp3-navbar-group bp3-align-left"><img src={WECHU} alt="wechu logo" style={{marginRight: "10px"}}/>
                </div>
                {currentUser
                  ? (
                    <div className="bp3-navbar-group bp3-align-right">
                      <Link className="bp3-button bp3-minimal bp3-icon-home" to="/">Home</Link>
                      <span className="bp3-navbar-divider"></span>
                      <ReviewerLink />
                      {/* <Link className="bp3-button bp3-minimal bp3-icon-user" to="/account">

                      </Link>
                      <span className="bp3-navbar-divider"></span> */}
                      <Link className="bp3-button bp3-minimal" to="/logout" >Log Out</Link>
                    </div>
                  ) 
                  : (
                    <div className="bp3-navbar-group bp3-align-right">
                      <Link className="bp3-button bp3-intent-primary" to="/login">Login</Link>
                    </div>
                  )
                }
              </div>
            </nav>
          </div>
        );
}

export default Header
