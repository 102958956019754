import React, {useCallback, useContext} from "react";
import {withRouter, Redirect} from "react-router";
import {app} from "./../../hoc/Base";
import {AuthContext} from "./../../hoc/Auth";
import { Card, Elevation } from "@blueprintjs/core";

const Login = ({ history }) => {
    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const {email,password} = event.target.elements;
            try{
                await app
                    .auth()
                    .signInWithEmailAndPassword(email.value, password.value);
                history.push("/awards");
            }
            catch (error){
            }
        },
            [history]
    );

    //get current user from AuthContext
    const {currentUser} = useContext(AuthContext);
    
    //Redirect if current user is there    
    if (currentUser) {
        return <Redirect to="/" />
    }

    return(
        <Card interactive={false} elevation={Elevation.THREE} className="loginStyles">
            <div>
                <form onSubmit={handleLogin}>
                        <label className="bp3-label">
                            Email
                        <input
                            style={{ width: "100%" }}
                            className="bp3-input"
                            name="email"
                            type="email"
                            placeholder="Email" ></input>
                    </label>

                    <label className="bp3-label">
                        Password
                    <input
                            style={{ width: "100%" }}
                            className="bp3-input"
                            name="password"
                            type="password"></input>
                    </label>
                    <input
                        style={{ width: "100%" }}
                        type="submit"
                        className="bp3-button bp3-intent-primary"
                        value="Login"></input>
                </form>
            </div>
        </Card>
    )
}


export default withRouter(Login);