import React, { Component } from 'react';

import SideMenu from '../components/Form/SideMenu';
import FileUpload from '../components/Form/FormItems/FileUpload';
import { Dialog, Radio, RadioGroup, TextArea } from '@blueprintjs/core';
import Notes from '../components/Form/Notes';
import Description from '../components/Description';
import { Redirect } from 'react-router-dom';

import { base } from '../hoc/Base';
import TopContainer from '../hoc/TopContainer';
import  Notification  from '../components/Notification';

import * as YEAR from '../constants/year';

class AdditionalDocuments extends Component {
    constructor(props) {
        super(props);
        this.handleForm = this.handleForm.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
//        this.handleLoad = this.handleLoad.bind(this);
        this.state = ({ 
            evaluation:'',
            evaluationCon: '',
            evaluation2: '',
            evaluationCon2:'',
            comprehensivePolicy: '',
            comprehensive:'',
            orgSocialRespActivity1:'',
            orgSocialRespActivity2:'',
            spportivePolicy:'',
            isOpen: false,
            radioValue: '',
            livingWage: '',
            winnersBooklet: '',
            userID: '',
            redirect: false,
            year: '',
        })
    }

    fieldChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    //handle raido 
    handleRadioChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    // handleLoad(){
    //     base.fetch(`awards/${this.state.userID}/GordSmith`, {
    //         context: this,
    //     }).then(data => {
    //         this.setState({
    //             formData: data,
    //             year: '2018'
    //         })

    //         if (this.props.topicLevel === 'additionalDocument') {
    //             if ('additionalDocument' in data){
    //                 this.setState({
    //                     evaluation: this.state.formData.additionalDocument[0].evaluation,
    //                     evaluationCon: this.state.formData.additionalDocument[1].evaluationCon,
    //                     evaluation2: this.state.formData.additionalDocument[2].evaluation2,
    //                     evaluationCon2:this.state.formData.additionalDocument[3].evaluationCon2,
    //                     comprehensivePolicy:this.state.formData.additionalDocument[4].comprehensivePolicy,
    //                     comprehensive: this.state.formData.additionalDocument[5].comprehensive,
    //                     orgSocialRespActivity1: this.state.formData.additionalDocument[6].orgSocialRespActivity1,
    //                     orgSocialRespActivity2: this.state.formData.additionalDocument[7].orgSocialRespActivity2,
    //                     spportivePolicy:this.state.formData.additionalDocument[8].spportivePolicy,
    //                     radioValue: this.state.formData.additionalDocument[9].radioValue,
    //                     livingWage: this.state.formData.additionalDocument[10].livingWage,
    //                 })
    //             }
    //         } else {
    //                 alert('object does not exist')
    //         }
            
    //     })
    // }

    //toggle dialog box off
    toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });

    //submitted
    submitted = () => {
        base.post(`submitted/${this.state.userID}`, {
            data: {submitted: 'submitted',
                   submittedOn: Date.now()}
        })
        console.log('submitted')
    }

    handleSubmit = () => {
         this.submitted()
         this.setState({
                redirect:true
            })
    }

    renderRedirect = () =>{
        if(this.state.redirect){
            return <Redirect to='/submitted' />
        }
    }

    //handle form data
    handleForm(event) {
        event.preventDefault()
        const topicData =
            [
                { evaluation: this.state.evaluation },
                { evaluationCon: this.state.evaluationCon},
                { evaluation2:this.state.evaluation2},
                { evaluationCon2:this.state.evaluationCon2},
                { comprehensivePolicy:this.state.comprehensivePolicy},
                { comprehensive: this.state.comprehensive },
                { orgSocialRespActivity1: this.state.orgSocialRespActivity1 },
                { orgSocialRespActivity2: this.state.orgSocialRespActivity2 },
                { spportivePolicy: this.state.spportivePolicy },
                { radioValue: this.state.radioValue },
                { livingWage:this.state.livingWage},
                { winnersBooklet: this.state.winnersBooklet }
            ]

        this.props.addFormData(topicData, this.props.topicLevel)
        this.setState({
            isOpen:true
        })
    }

    componentDidMount() {
        const { match: {params} } = this.props;

        this.setState({
            userID: params.uid
        })

        base.fetch(`awards/${params.uid}/${YEAR.CURRENT_YEAR}/GordSmith`, {
            context: this,
        }).then(data => {
            this.setState({
                formData: data
            })

            if (this.props.topicLevel === 'additionalDocument') {
                if ('additionalDocument' in data){
                    this.setState({
                        evaluation: this.state.formData.additionalDocument[0].evaluation,
                        evaluationCon: this.state.formData.additionalDocument[1].evaluationCon,
                        evaluation2: this.state.formData.additionalDocument[2].evaluation2,
                        evaluationCon2:this.state.formData.additionalDocument[3].evaluationCon2,
                        comprehensivePolicy:this.state.formData.additionalDocument[4].comprehensivePolicy,
                        comprehensive: this.state.formData.additionalDocument[5].comprehensive,
                        orgSocialRespActivity1: this.state.formData.additionalDocument[6].orgSocialRespActivity1,
                        orgSocialRespActivity2: this.state.formData.additionalDocument[7].orgSocialRespActivity2,
                        spportivePolicy:this.state.formData.additionalDocument[8].spportivePolicy,
                        radioValue: this.state.formData.additionalDocument[9].radioValue,
                        livingWage: this.state.formData.additionalDocument[10].livingWage,
                        winnersBooklet: this.state.formData.additionalDocument[11].winnersBooklet,
                    })
                }
            } else {
                   alert('object does not exist')
            }
            
        })
        
    }

    render() {
        return (
            <div className="form">
                <div>
                    {this.renderRedirect()}
                </div>
                <Dialog
                    isOpen={this.state.isOpen}
                    onClose={this.toggleDialog}
                    title={this.props.topicLevel}>
                    <div className="bp3-dialog-body">
                        <h3>Additional Documents Saved</h3>
                        <p> Remember you can always come back and edit your information.</p>
                        <div>
                            <button onClick={()=>this.handleSubmit()}
                            className="bp3-button bp3-intent-success bp3-fill"><p>Submit Application</p></button>
                        </div>
                    </div>
                    
                </Dialog>

                <div className="side-menu">
                    <SideMenu
                        awardType={this.props.awardType}
                        awardLevel={this.props.awardLevel} 
                        user={this.props.user} />
                    <Notification 
                        awardType={this.props.awardType}
                        awardLevel={this.props.awardLevel}
                        user={this.props.user}/>
                </div>

                

                <div className="application-form">

                <div><h2 style={{ fontSize: "50px", color: "#FFFFFF", margin: "0px 0px 10px" }}>Additional Documents</h2></div>

                {/* <div><Button onClick={this.handleLoad} role="button" className="bp3-button bp3-intent-primary" tabIndex="0">Load 2018 Additional Documents</Button></div> */}
                    <TopContainer>

                         
                        <form onSubmit={(event) => this.handleForm(event)} ref={(form) => this.topicForm = form}>

                        {/* ----------------------Evaluation---------------------- */}
                          
                                <div className="bp3-card silver">
                                    <Description 
                                    panelDes="Evaluation"/>
                                    <h5>Evaluation</h5>

                                    {this.props.awardLevel === "Gold Level" || this.props.awardLevel === "Platinum" ||this.props.awardLevel === "Diamond" ?
                                    <p>Provide evidence of a type of evaluation you completed for TWO of the activities you listed under awareness raising, education and skill building, or environmental support in the previous sections.</p> :
                                    <p>Provide evidence of a type of evaluation you completed for ONE of the activities you listed under awareness raising, education and skill building, or environmental support in the previous sections.</p> 
        }
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of activity you're providing evaluation for</label>
                                        <div>
                                            <input
                                                className="bp3-input"
                                                type="Text"
                                                name="evaluation"
                                                ref={(input) => { this.evaluation = input }}
                                                onChange={e => this.fieldChange(e)}
                                                value={this.state.evaluation}
                                                placeholder="Name of activity" />
                                        </div>

                                        <label className="bp3-label">How evaluation was conducted</label>
                                        <div>
                                            <TextArea
                                                name="evaluationCon"
                                                style={{ width: "100%", height: "100px" }}
                                                onChange={e => this.fieldChange(e)}
                                                value={this.state.evaluationCon}
                                            />
                                        </div>

                                        <div>
                                            <label className="bp3-label">Attach Evidence of Evaluation</label>   

                                            <FileUpload
                                                awardType='GordSmith'
                                                uploadPane="evaluation"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />
                                        </div>

                                        <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='evaluation'
                                            />
                                    </div>

                                 {this.props.awardLevel === "Gold Level" || this.props.awardLevel === "Platinum" || this.props.awardLevel === "Diamond"   ?
                                
                                <div className="bp3-card bp3-elevation-2">
                                     <label className="bp3-label">Name of activity you're providing evaluation for</label>
                                     <div>
                                         <input
                                             className="bp3-input"
                                             type="Text"
                                             name="evaluation2"
                                             ref={(input) => { this.evaluation2 = input }}
                                             onChange={e => this.fieldChange(e)}
                                             value={this.state.evaluation2}
                                             placeholder="Name of activity" />
                                     </div>

                                     <label className="bp3-label">How evaluation was conducted</label>
                                     <div>
                                         <TextArea
                                             name="evaluationCon2"
                                             style={{ width: "100%", height: "100px" }}
                                             onChange={e => this.fieldChange(e)}
                                             value={this.state.evaluationCon2}
                                         />
                                     </div>

                                     <div>
                                         <label className="bp3-label">Attach Evidence of Evaluation</label>
                                         <FileUpload
                                             awardType='GordSmith'
                                             uploadPane="evaluation2"
                                             topicLevel={this.props.topicLevel}
                                             user={this.props.user} />
                                     </div>

                                     <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='evaluation2'
                                            />
                                 </div>
                             : null
                            }
                            </div>



                            {/* ----------------------Comprehensive Workplace Wellness Policy---------------------- */}
                            {this.props.awardLevel === "Silver Level" || this.props.awardLevel === "Gold Level" || this.props.awardLevel === "Platinum" || this.props.awardLevel === "Diamond" ?
                                <div className="bp3-card silver">
                                    <Description 
                                    panelDes="Comprehensive Workplace Wellness Policy"/>
                                    <h5>Comprehensive Workplace Wellness Policy</h5>

                                    <p>Please provide a copy of your Comprehensive Workplace Wellness Policy</p>
                                    <div className="bp3-card bp3-elevation-2">
                                        <div>
                                            <input
                                                className="bp3-input"
                                                type="Text"
                                                name="comprehensivePolicy"
                                                ref={(input) => { this.comprehensivePolicy = input }}
                                                onChange={e => this.fieldChange(e)}
                                                value={this.state.comprehensivePolicy}
                                                placeholder="Name of policy" />
                                        </div>
                                        <div>
                                            <label className="bp3-label">Attach Policy</label>
                                            <FileUpload
                                                year={this.state.year}
                                                awardType='GordSmith'
                                                uploadPane="comprehensive"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />
                                        </div>

                                        <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='comprehensive'
                                            />
                                    </div>
                                </div> : null}

                            {/* ----------------------Organizational Social Responsibility---------------------- */}
                            {this.props.awardLevel === "Gold Level" || this.props.awardLevel === "Platinum" || this.props.awardLevel === "Diamond" ?
                                <div className="bp3-card silver">
                                    <Description 
                                    panelDes="Organizational Social Responsibility"/>
                                    <h5>Organizational Social Responsibility</h5>


                                    {this.props.awardLevel === "Platinum" || this.props.awardLevel === "Diamond" ?
                                    <p>Provide TWO examples of an activity or program where your workplace is active in the community to support efforts to improve the health and well-being of the community.</p> : 
                                    <p>Provide ONE examples of an activity or program where your workplace is active in the community to support efforts to improve the health and well-being of the community.</p>
                        }
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of Activity</label>
                                        <div>
                                            <input
                                                className="bp3-input"
                                                type="Text"
                                                name="orgSocialRespActivity1"
                                                ref={(input) => { this.orgSocialRespActivity1 = input }}
                                                onChange={e => this.fieldChange(e)}
                                                value={this.state.orgSocialRespActivity1}
                                                placeholder="Name of Activity" />
                                        </div>
                                        <div>
                                            <label className="bp3-label">Attach evidence for this activity</label>
                                            <FileUpload
                                                awardType='GordSmith'
                                                uploadPane="orgSocialRespActivity1"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />
                                        </div>

                                        <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='orgSocialRespActivity1'
                                            />
                                    </div>
                                    {this.props.awardLevel === "Platinum" || this.props.awardLevel === "Diamond" ?
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of Activity</label>
                                        <div>
                                            <input
                                                className="bp3-input"
                                                type="Text"
                                                name="orgSocialRespActivity2"
                                                ref={(input) => { this.orgSocialRespActivity2 = input }}
                                                onChange={e => this.fieldChange(e)}
                                                value={this.state.orgSocialRespActivity2}
                                                placeholder="Name of Activity" />
                                        </div>
                                        <div>
                                            <label className="bp3-label">Attach evidence for activity</label>
                                            <FileUpload
                                                awardType='GordSmith'
                                                uploadPane="orgSocialRespActivity2"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />
                                        </div>

                                        <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='orgSocialRespActivity2'
                                            />
                                    </div> : null
                                    }
                                </div> : null}

                            {/* ----------------------Supportive Policy Requirement---------------------- */}
                            {/* {this.props.awardLevel === "Bronze Level" || this.props.awardLevel === "Silver Level" ?
                                <div className="bp3-card silver">
                                    <Description 
                                    panelDes="Supportive Policy Requirement"/>
                                    <h5>Supportive Policy Requirement</h5>


                                    {this.props.awardLevel === "Silver Level 1" ? <p>Provide ONE supportive policies specific to the wellness topic chosen</p>
                                        : null
                                    }
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of policy specific to the wellness topic chosen</label>
                                        <div>
                                            <input
                                                className="bp3-input"
                                                type="Text"
                                                name="spportivePolicy"
                                                ref={(input) => { this.spportivePolicy = input }}
                                                onChange={e => this.fieldChange(e)}
                                                value={this.state.spportivePolicy}
                                                placeholder="Name of policy" />
                                        </div>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach Policy</label>
                                            <FileUpload
                                                awardType='GordSmith'
                                                uploadPane="supportivePolicyReq"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />
                                        </div>

                                        <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='supportivePolicyReq'
                                            />
                                    </div>
                                </div> : null} */}

                            {/* ----------------------Living Wage---------------------- */}
                            {this.props.awardLevel === "Platinum" || this.props.awardLevel === "Diamond"  ?
                                <div className="bp3-card silver">
                                    <Description 
                                    panelDes="Living Wage"/>
                                    <h5>Living Wage Employer Certification</h5>
                                        

                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">My workplace is a Windsor and Essex County Living Wage Certified Employer </label>
                                        <RadioGroup
                                        name="livingWage"
                                        onChange={this.handleRadioChange}
                                        selectedValue={this.state.livingWage}>

                                        <Radio 
                                            className="bp3-control bp3-radio bp3-large" 
                                            label="Yes" 
                                            value="Yes" />

                                        <Radio 
                                            className="bp3-control bp3-radio bp3-large" 
                                            label="No" 
                                            value="No" />

                                    </RadioGroup>
                                    </div>
                                    {this.state.livingWage === "No" ? 
                                    <p>If you are not a living wage employer, you will not be eligible for the Diamond or Platinum level awards. Please visit <a href="http://www.wechu.org/living-wage" target="_blank" rel="noopener noreferrer">www.wechu.org/living-wage</a> to learn more, and to apply to become a Certified Living Wage Employer. Applications are typically reviewed and a decision is provided within 10 days of applying.</p>
                                    : null
                                    }

                                    <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='livingwage'
                                            />
                                    
                                </div> : null}


                            {/* ----------------------Diamond Eligibility---------------------- */}
                            {this.props.awardLevel === "Diamond" ?
                                <div className="bp3-card silver">
                                    <h5>Diamond Eligibility</h5>
                                    <div className="bp3-card bp3-elevation-2">

                                    <RadioGroup
                                        label="My workplace is eligible for this award because"
                                        name="radioValue"
                                        onChange={this.handleRadioChange}
                                        selectedValue={this.state.radioValue}>

                                        <Radio 
                                            className="bp3-control bp3-radio bp3-large" 
                                            label="My workplace met Platinum Level Award criteria for the last two years in a row" 
                                            value="one" />

                                        <Radio 
                                            className="bp3-control bp3-radio bp3-large" 
                                            label="My workplace received a Diamond Level Award last year" 
                                            value="two" />

                                    </RadioGroup>
                                    </div>

                                    <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='diamonElig'
                                            />
                                </div> : null}

                            
                            {/* ----------------------Orgainzation heighligt---------------------- */}        
                            <div className="bp3-card silver">
                                <h5>Organization Highlight</h5>
                                <p>Please provide a brief write-up that describes one of the wellness activities included in your application  (200-word maximum). This will be read from the podium at the Awards Ceremony  and displayed on the Windsor-Essex County Health Unit’s website.</p>
                                <div className="bp3-card bp3-elevation-2">
                                <TextArea
                                    name="winnersBooklet"
                                    style={{ width: "100%", height: "250px" }}
                                    onChange={e => this.fieldChange(e)}
                                    value={this.state.winnersBooklet}
                                    />
                                </div>

                                <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='booklet'
                                            />
                            </div>

                            <input
                                style={{ right:"50px" , width: "250px", position:"fixed", bottom:"30px" }}
                                type="submit"
                                className="bp3-button bp3-intent-primary bp3-large"
                                value="Save Additional Documents"></input>
                        </form>
                    </TopContainer>
                </div>
            </div>
        );
    }
}

export default AdditionalDocuments;
