import React, { Component } from 'react';

import TopContainer from '../hoc/TopContainer';
import FileDownload from '../components/Form/FormItems/FileDownload';
import { base } from '../hoc/Base';
import { Dialog, Radio, RadioGroup} from '@blueprintjs/core';
import ReviewerNotes from '../components/Form/ReviewerNotes';

import * as YEAR from '../constants/year';


class ReviewBF extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            awardLevel:'',
            isOpen: false,
            policy: '',
            promotion: '',
            promotionDec: '',
            education: '',
            educationDec: '',
            environmental: '',
            incentives: '',
            policyStatment: '',
            radioValue:'',
            winnersBooklet: '',
            formData: [],
            org: ''
        })
    }


    //toggle dialog box off
    toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });

    componentDidMount() {
        const { match: {params} } = this.props;
        this.setState({
            org: params
        })

        base.fetch(`awards/${params.uid}/${YEAR.CURRENT_YEAR}/BikeFriendly`, {
            context: this,
        }).then(data => {
            this.setState({
                formData: data,
                awardLevel: data.awardLevel
            })
            if ('bikeFriendly' in data){
                    this.setState({
                        policy: this.state.formData.bikeFriendly[0].policy,
                        promotion: this.state.formData.bikeFriendly[1].promotion,
                        promotionDec: this.state.formData.bikeFriendly[2].promotionDec,
                        education: this.state.formData.bikeFriendly[3].education,
                        educationDec: this.state.formData.bikeFriendly[4].educationDec,
                        environmental: this.state.formData.bikeFriendly[5].environmental,
                        incentives: this.state.formData.bikeFriendly[6].incentives,
                        policyStatment: this.state.formData.bikeFriendly[7].policyStatment,
                        radioValue: this.state.formData.bikeFriendly[8].radioValue,
                        winnersBooklet:this.state.formData.bikeFriendly[9].winnersBooklet
                })
            }
        })
    }

    render() {
        return (
            <div>
    
                <Dialog
                    isOpen={this.state.isOpen}
                    onClose={this.toggleDialog}
                    title={this.props.topicLevel}>
                    <div className="bp3-dialog-body">
                        <h3>Application Saved</h3>
                        <p> Remember you can always come back and edit your information.</p>
                    </div>
                    
                </Dialog>

                    <TopContainer>
                            <h2>{this.state.awardLevel}</h2>
                            {this.state.awardLevel === 'Bronze Level' ||
                             this.state.awardLevel === 'Silver Level' ||
                             this.state.awardLevel === 'Gold Level' ||
                             this.state.awardLevel === 'Platinum' ||
                             this.state.awardLevel === 'Platinum Plus' ?
                             <div>
                                {/* ----------------------Promotion---------------------- */}
                                <div className="bp3-card silver">
                                    <h5>Promotion</h5>
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Type of promotion </label>
                                        <div className="bp3-card">
                                            <p>{this.state.promotion}</p>
                                        </div>

                                        <label className="bp3-label">Description of how activity was conducted</label>
                                        <div className="bp3-card">
                                            <p>{this.state.promotionDec}</p>
                                        </div>

                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                            <FileDownload
                                                    awardType='BikeFriendly' 
                                                    BFuploadPane="promotion"
                                                    topicLevel={this.props.topicLevel}
                                                    org={this.state.org.uid} />

                                        </div>
                                        <ReviewerNotes 
                                        panel='promotion'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    </div>
                                </div>

                                {/* ----------------------Education---------------------- */}
                                <div className="bp3-card silver">
                                    <h5>Education</h5>
                                    <div className="bp3-card bp3-elevation-2">
                                        
                                        <label className="bp3-label">Name of Activity </label>
                                        <div className="bp3-card">
                                            <p>{this.state.education} </p>
                                        </div>

                                        <label className="bp3-label">How education was provided </label>
                                        <div>
                                            <p className="bp3-card">{this.state.educationDec}</p>
                                        </div>

                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                            <FileDownload
                                                    awardType='BikeFriendly' 
                                                    BFuploadPane="education"
                                                    topicLevel={this.props.topicLevel}
                                                    org={this.state.org.uid} />

                                        </div>
                                        <ReviewerNotes 
                                        panel='education'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    </div>
                                </div>

                                {/* ----------------------Environmental Supports---------------------- */}
                                <div className="bp3-card silver">
                                    <h5>Environmental Supports</h5>
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Type of environmental support provided </label>
                                        <div  className="bp3-card">
                                            <p>{this.state.environmental}</p>
                                        </div>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                            <FileDownload
                                                    awardType='BikeFriendly' 
                                                    BFuploadPane="environmental"
                                                    topicLevel={this.props.topicLevel}
                                                    org={this.state.org.uid} />

                                        </div>
                                        <ReviewerNotes 
                                        panel='environmental'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    </div>
                                </div>

                                {/* ----------------------Incentives---------------------- */}
                                <div className="bp3-card silver">
                                    <h5>Incentives</h5>
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Type of incentives provided</label>
                                        <div  className="bp3-card">
                                            <p>{this.state.incentives}</p>
                                        </div>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                            <FileDownload
                                                    awardType='BikeFriendly' 
                                                    BFuploadPane="incentives"
                                                    topicLevel={this.props.topicLevel}
                                                    org={this.state.org.uid} />

                                        </div>
                                        <ReviewerNotes 
                                        panel='incentives'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    </div>
                                </div>
                                
                                

                                  {/* ----------------------Policy---------------------- */}

                                <div className="bp3-card silver">
                                    <h5>Policy</h5>
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of policy</label>
                                        <div className="bp3-card">
                                            <p>{this.state.policy} </p>
                                        </div>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach policy</label>

                                            <FileDownload
                                                    awardType='BikeFriendly' 
                                                    BFuploadPane="policy"
                                                    topicLevel={this.props.topicLevel}
                                                    org={this.state.org.uid} />
                                        </div>
                                        <ReviewerNotes 
                                        panel='policy'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    </div>
                                </div>

                                {/* ----------------------Bicycle Policy Statement---------------------- */}        
                                {this.state.awardLevel === 'Platinum Plus' ?
                                <div className="bp3-card silver">
                                    <h5>Provide a 'Pro' Bicycle Policy Statement</h5>
                                    
                                
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Provide evidence that all worksites are able to support employees cycling to their place of work</label>

                                            <FileDownload
                                                    awardType='BikeFriendly' 
                                                    BFuploadPane="pro"
                                                    topicLevel={this.props.topicLevel}
                                                    org={this.state.org.uid} />

                                        </div>
                                        <ReviewerNotes 
                                        panel='pro'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    
                                </div> : null
                                }

                                {/* ----------------------Multiple Site---------------------- */}
                                { this.state.awardLevel === 'Platinum Plus' ?
                                    <div className="bp3-card silver">
                                        <h5>Multiple Site</h5>
                                        <div className="bp3-card bp3-elevation-2">

                                        <RadioGroup

                                            name="group"
                                            onChange={this.handleRadioChange}
                                            selectedValue={this.state.radioValue}>

                                            <Radio 
                                                className="bp3-control bp3-radio bp3-large" 
                                                label=" My workplace does not have multiple sites." 
                                                value="one" />

                                            <Radio 
                                                className="bp3-control bp3-radio bp3-large" 
                                                label="My workplace has multiple sites" 
                                                value="two" />

                                        </RadioGroup>

                                        <div>

                                            <FileDownload
                                                    awardType='BikeFriendly' 
                                                    BFuploadPane="multi-site"
                                                    topicLevel={this.props.topicLevel}
                                                    org={this.state.org.uid} />

                                        </div>
                                        <ReviewerNotes 
                                        panel='multi-site'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                        </div>
                                    </div>: null
                                }

                                {/* ----------------------Winners's Booklet---------------------- */}        
                                <div className="bp3-card silver">
                                    <h5>Winners's Booklet</h5>
                                    <p>Please provide a brief write-up that highlights one of your wellness activities throughout the year (200-word maximum)</p>
                                    
                                   <div className="bp3-card"> <p>{this.state.winnersBooklet}</p></div>
                                   <ReviewerNotes 
                                        panel='booklet'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                </div>
                            </div>
                        : null}
                    </TopContainer>
                </div>
            
        );
    }
}

export default ReviewBF;
