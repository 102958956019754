import React, { Component } from 'react';
import { Icon } from '@blueprintjs/core';

import * as YEAR from '../constants/year';

import { base } from '../hoc/Base';

class Notification extends Component {
    constructor(props) {
        super(props);

        this.state =({
            topic1AwarnessRaising: 'cross',
            topic1EduOrSkillBuilding:'cross',
            topic1EnvSupport:'cross',
            topic1SupportivePolicy:'cross',
            topic2AwarnessRaising: 'cross',
            topic2EduOrSkillBuilding:'cross',
            topic2EnvSupport:'cross',
            topic2SupportivePolicy:'cross',
            topic3AwarnessRaising: 'cross',
            topic3EduOrSkillBuilding:'cross',
            topic3EnvSupport:'cross',
            topic3SupportivePolicy:'cross',
            evaluation:'cross',
            evaluation2:'cross',
            comprehensivePolicy:'cross',
            comprehensive:'cross',
            orgSocialRespActivity:'cross',
            spportivePolicy:'cross',
            livingWage:'cross',
            diamondEligibility:'cross',
            orgHighlight:'cross'
        })
    }
    //Create a new object
    target = {}

    //Check if field is empty
    fieldCheck = (mergedTarget) =>{
        console.log(mergedTarget)
        let state = 'tick'
        for(let key in mergedTarget){
            if(mergedTarget[key] === ""){
                state = 'cross'
                break
            }
        }
        //reset target Object
        this.target={}
        return state
    }

    componentDidMount() {
        base.fetch(`awards/${this.props.user}/${YEAR.CURRENT_YEAR}/GordSmith`, {
            context: this,
        }).then(data => {
            //Merge the object into the target object
            let merger = (obj) => {
                for (let prop in obj){
                    if (obj.hasOwnProperty(prop)){
                        //Push each value from 'obj' into 'target'
                        this.target[prop] = obj[prop]
                    }
                }
            }

            //Check if object has any saved topic1
                switch(this.props.awardLevel){
                    case 'Rising Star':
                        if(data.hasOwnProperty('topic1') && data.hasOwnProperty('files')){
                            //Topic1
                            if(data.files.hasOwnProperty('topic1')){
                            //Awarness Raising
                                if(data.files.topic1.hasOwnProperty('filesForAwareness1')){
                                    for(let i=1; i < 3; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic1.hasOwnProperty('filesForEducation1')){
                                    for(let i=5; i < 7; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic1.hasOwnProperty('filesForEnvironmental1')){
                                    for(let i=9; i < 11; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                                //Statement
                                if(data.files.topic1.hasOwnProperty('statement')){
                                    this.setState({
                                        topic1SupportivePolicy: 'tick'
                                    })
                                }
                                //Organization Heightlight
                                this.setState({
                                    orgHighlight: this.fieldCheck(data.topic1[16])
                                })
                            }
                        }
                        break;
                    case 'Bronze Level':
                        if(data.hasOwnProperty('topic1') && data.hasOwnProperty('files')){
                            //Topic1
                            if(data.files.hasOwnProperty('topic1')){
                                //Awarness Raising
                                if(data.files.topic1.hasOwnProperty('filesForAwareness1')){
                                    for(let i=1; i < 3; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                if(data.files.topic1.hasOwnProperty('filesForPolicy1')){
                                    this.setState({
                                        spportivePolicy:this.fieldCheck(data.topic1[13])
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic1.hasOwnProperty('filesForEducation1')){
                                    for(let i=5; i < 7; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic1.hasOwnProperty('filesForEnvironmental1')){
                                    for(let i=9; i < 11; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                         //Additional Documents
                         //Evaluation
                         if(data.hasOwnProperty('additionalDocument') && data.hasOwnProperty('files')){
                             console.log(data.additionalDocument[11])
                             //Org heighlight
                             this.setState({
                                 orgHighlight: this.fieldCheck(data.additionalDocument[11])
                             })
                            if(data.files.hasOwnProperty('additionalDocument')){
                                if(data.files.additionalDocument.hasOwnProperty('evaluation')){
                                    for(let i=0; i < 2; i++){
                                        merger(data.additionalDocument[i])
                                    }
                                    this.setState({
                                        evaluation: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                        break;
                    case 'Silver Level':
                        if(data.hasOwnProperty('topic1') && data.hasOwnProperty('files')){
                            //Topic1
                            if(data.files.hasOwnProperty('topic1')){
                                //Awarness Raising
                                if(data.files.topic1.hasOwnProperty('filesForAwareness1')){
                                    for(let i=1; i < 3; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic1.hasOwnProperty('filesForEducation1')){
                                    for(let i=5; i < 7; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic1.hasOwnProperty('filesForEnvironmental1')){
                                    for(let i=9; i < 11; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                        if(data.hasOwnProperty('topic2') && data.hasOwnProperty('files')){
                            //Topic2
                            if(data.files.hasOwnProperty('topic2')){
                                //Awarness Raising
                                if(data.files.topic2.hasOwnProperty('filesForAwareness1')){
                                    for(let i=1; i < 3; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic2.hasOwnProperty('filesForEducation1')){
                                    for(let i=5; i < 7; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic2.hasOwnProperty('filesForEnvironmental1')){
                                    for(let i=9; i < 11; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                         //Additional Documents
                         //Evaluation
                         if(data.hasOwnProperty('additionalDocument') && data.hasOwnProperty('files')){
                             console.log(data.additionalDocument[11])
                             //Org heighlight
                             
                            if(data.files.hasOwnProperty('additionalDocument')){
                                if(data.files.additionalDocument.hasOwnProperty('evaluation')){
                                    for(let i=0; i < 2; i++){
                                        merger(data.additionalDocument[i])
                                    }
                                    this.setState({
                                        evaluation: this.fieldCheck(this.target)
                                    })
                                }
                                if(data.files.additionalDocument.hasOwnProperty('comprehensive')){
                                    this.setState({
                                        comprehensivePolicy: this.fieldCheck(data.additionalDocument[4])
                                    })
                                }
                            }
                            this.setState({
                                orgHighlight: this.fieldCheck(data.additionalDocument[11])
                            })
                        }
                        break;
                    case 'Gold Level':
                        if(data.hasOwnProperty('topic1') && data.hasOwnProperty('files')){
                            //Topic1
                            if(data.files.hasOwnProperty('topic1')){
                                //Awarness Raising
                                if(data.files.topic1.hasOwnProperty('filesForAwareness1')){
                                    for(let i=1; i < 3; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic1.hasOwnProperty('filesForEducation1')){
                                    for(let i=5; i < 7; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic1.hasOwnProperty('filesForEnvironmental1')){
                                    for(let i=9; i < 11; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                        if(data.hasOwnProperty('topic2') && data.hasOwnProperty('files')){
                            //Topic2
                            if(data.files.hasOwnProperty('topic2')){
                                //Awarness Raising
                                if(data.files.topic2.hasOwnProperty('filesForAwareness1')){
                                    for(let i=1; i < 3; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic2.hasOwnProperty('filesForEducation1')){
                                    for(let i=5; i < 7; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic2.hasOwnProperty('filesForEnvironmental1')){
                                    for(let i=9; i < 11; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                        if(data.hasOwnProperty('topic3') && data.hasOwnProperty('files')){
                            //Topic3
                            if(data.files.hasOwnProperty('topic3')){
                                //Awarness Raising
                                if(data.files.topic3.hasOwnProperty('filesForAwareness1')){
                                    for(let i=1; i < 3; i++){
                                        merger(data.topic3[i])
                                    }
                                    this.setState({
                                        topic3AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic3.hasOwnProperty('filesForEducation1')){
                                    for(let i=5; i < 7; i++){
                                        merger(data.topic3[i])
                                    }
                                    this.setState({
                                        topic3EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic3.hasOwnProperty('filesForEnvironmental1')){
                                    for(let i=9; i < 11; i++){
                                        merger(data.topic3[i])
                                    }
                                    this.setState({
                                        topic3EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                         //Additional Documents
                         //Evaluation
                         if(data.hasOwnProperty('additionalDocument') && data.hasOwnProperty('files')){
                             console.log(data.additionalDocument[11])
                             
                             if(data.files.hasOwnProperty('additionalDocument')){
                                 if(data.files.additionalDocument.hasOwnProperty('evaluation') && data.files.additionalDocument.hasOwnProperty('evaluation2')){
                                     for(let i=0; i < 4; i++){
                                         merger(data.additionalDocument[i])
                                        }
                                        this.setState({
                                            evaluation: this.fieldCheck(this.target)
                                        })
                                    }
                                    if(data.files.additionalDocument.hasOwnProperty('comprehensive')){
                                        this.setState({
                                            comprehensivePolicy: this.fieldCheck(data.additionalDocument[4])
                                        })
                                    }
                                    if(data.files.additionalDocument.hasOwnProperty('orgSocialRespActivity1')){
                                        this.setState({
                                            orgSocialRespActivity: this.fieldCheck(data.additionalDocument[6])
                                        })
                                    }
                                }
                            //Org heighlight
                            this.setState({
                                orgHighlight: this.fieldCheck(data.additionalDocument[11])
                            })
                        }
                        break;
                    case 'Platinum':
                        if(data.hasOwnProperty('topic1') && data.hasOwnProperty('files')){
                            //Topic1
                            if(data.files.hasOwnProperty('topic1')){
                                //Awarness Raising
                                if(data.files.topic1.hasOwnProperty('filesForAwareness1') && data.files.topic1.hasOwnProperty('filesForAwareness2')){
                                    for(let i=1; i <= 4; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic1.hasOwnProperty('filesForEducation1') && data.files.topic1.hasOwnProperty('filesForEducation2')){
                                    for(let i=5; i <= 8; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic1.hasOwnProperty('filesForEnvironmental1') && data.files.topic1.hasOwnProperty('filesForEnvironmental2')){
                                    for(let i=9; i <= 12; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                        if(data.hasOwnProperty('topic2') && data.hasOwnProperty('files')){
                            //Topic2
                            if(data.files.hasOwnProperty('topic2')){
                                //Awarness Raising
                                if(data.files.topic2.hasOwnProperty('filesForAwareness1') && data.files.topic2.hasOwnProperty('filesForAwareness2')){
                                    for(let i=1; i <= 4; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic2.hasOwnProperty('filesForEducation1') && data.files.topic2.hasOwnProperty('filesForEducation2')){
                                    for(let i=5; i <= 8; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic2.hasOwnProperty('filesForEnvironmental1') && data.files.topic2.hasOwnProperty('filesForEnvironmental2')){
                                    for(let i=9; i <= 12; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                        if(data.hasOwnProperty('topic3') && data.hasOwnProperty('files')){
                            //Topic3
                            if(data.files.hasOwnProperty('topic3')){
                                //Awarness Raising
                                if(data.files.topic3.hasOwnProperty('filesForAwareness1') && data.files.topic3.hasOwnProperty('filesForAwareness2')){
                                    for(let i=1; i <= 4; i++){
                                        merger(data.topic3[i])
                                    }
                                    this.setState({
                                        topic3AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic3.hasOwnProperty('filesForEducation1') && data.files.topic3.hasOwnProperty('filesForEducation2')){
                                    for(let i=5; i <= 8; i++){
                                        merger(data.topic3[i])
                                    }
                                    this.setState({
                                        topic3EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic3.hasOwnProperty('filesForEnvironmental1') && data.files.topic3.hasOwnProperty('filesForEnvironmental2')){
                                    for(let i=9; i <= 12; i++){
                                        merger(data.topic3[i])
                                    }
                                    this.setState({
                                        topic3EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                         //Additional Documents
                         //Evaluation
                         if(data.hasOwnProperty('additionalDocument') && data.hasOwnProperty('files')){
                             console.log(data.additionalDocument[11])
                             
                             if(data.files.hasOwnProperty('additionalDocument')){
                                 if(data.files.additionalDocument.hasOwnProperty('evaluation') && data.files.additionalDocument.hasOwnProperty('evaluation2')){
                                     for(let i=0; i < 4; i++){
                                         merger(data.additionalDocument[i])
                                        }
                                        this.setState({
                                            evaluation: this.fieldCheck(this.target)
                                        })
                                    }
                                    if(data.files.additionalDocument.hasOwnProperty('comprehensive')){
                                        this.setState({
                                            comprehensivePolicy: this.fieldCheck(data.additionalDocument[4])
                                        })
                                    }
                                    if(data.files.additionalDocument.hasOwnProperty('orgSocialRespActivity1')){
                                        this.setState({
                                            orgSocialRespActivity: this.fieldCheck(data.additionalDocument[6])
                                        })
                                    }
                                }
                            //Org heighlight
                            this.setState({
                                orgHighlight: this.fieldCheck(data.additionalDocument[11])
                            })
                        }
                        break;
                    case 'Diamond':
                        if(data.hasOwnProperty('topic1') && data.hasOwnProperty('files')){
                            //Topic1
                            if(data.files.hasOwnProperty('topic1')){
                                //Awarness Raising
                                if(data.files.topic1.hasOwnProperty('filesForAwareness1') && data.files.topic1.hasOwnProperty('filesForAwareness2')){
                                    for(let i=1; i <= 4; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic1.hasOwnProperty('filesForEducation1') && data.files.topic1.hasOwnProperty('filesForEducation2')){
                                    for(let i=5; i <= 8; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic1.hasOwnProperty('filesForEnvironmental1') && data.files.topic1.hasOwnProperty('filesForEnvironmental2')){
                                    for(let i=9; i <= 12; i++){
                                        merger(data.topic1[i])
                                    }
                                    this.setState({
                                        topic1EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                        if(data.hasOwnProperty('topic2') && data.hasOwnProperty('files')){
                            //Topic2
                            if(data.files.hasOwnProperty('topic2')){
                                //Awarness Raising
                                if(data.files.topic2.hasOwnProperty('filesForAwareness1') && data.files.topic2.hasOwnProperty('filesForAwareness2')){
                                    for(let i=1; i <= 4; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic2.hasOwnProperty('filesForEducation1') && data.files.topic2.hasOwnProperty('filesForEducation2')){
                                    for(let i=5; i <= 8; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic2.hasOwnProperty('filesForEnvironmental1') && data.files.topic2.hasOwnProperty('filesForEnvironmental2')){
                                    for(let i=9; i <= 12; i++){
                                        merger(data.topic2[i])
                                    }
                                    this.setState({
                                        topic2EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                        if(data.hasOwnProperty('topic3') && data.hasOwnProperty('files')){
                            //Topic3
                            if(data.files.hasOwnProperty('topic3')){
                                //Awarness Raising
                                if(data.files.topic3.hasOwnProperty('filesForAwareness1') && data.files.topic3.hasOwnProperty('filesForAwareness2')){
                                    for(let i=1; i <= 4; i++){
                                        merger(data.topic3[i])
                                    }
                                    this.setState({
                                        topic3AwarnessRaising: this.fieldCheck(this.target)
                                    })
                                }
                                //Education or Skill Building
                                if(data.files.topic3.hasOwnProperty('filesForEducation1') && data.files.topic3.hasOwnProperty('filesForEducation2')){
                                    for(let i=5; i <= 8; i++){
                                        merger(data.topic3[i])
                                    }
                                    this.setState({
                                        topic3EduOrSkillBuilding: this.fieldCheck(this.target)
                                    })
                                }
                                //Environmental Support
                                if(data.files.topic3.hasOwnProperty('filesForEnvironmental1') && data.files.topic3.hasOwnProperty('filesForEnvironmental2')){
                                    for(let i=9; i <= 12; i++){
                                        merger(data.topic3[i])
                                    }
                                    this.setState({
                                        topic3EnvSupport: this.fieldCheck(this.target)
                                    })
                                }
                            }
                        }
                            //Additional Documents
                            //Evaluation
                            if(data.hasOwnProperty('additionalDocument') && data.hasOwnProperty('files')){
                                console.log(data.additionalDocument[11])
                                
                                if(data.files.hasOwnProperty('additionalDocument')){
                                    //evaluation
                                    if(data.files.additionalDocument.hasOwnProperty('evaluation') && data.files.additionalDocument.hasOwnProperty('evaluation2')){
                                        for(let i=0; i < 4; i++){
                                            merger(data.additionalDocument[i])
                                        }
                                        this.setState({
                                            evaluation: this.fieldCheck(this.target)
                                        })
                                    }
                                    //comprehensive
                                    if(data.files.additionalDocument.hasOwnProperty('comprehensive')){
                                        this.setState({
                                            comprehensivePolicy: this.fieldCheck(data.additionalDocument[4])
                                        })
                                    }
                                    //org social responsibility
                                    if(data.files.additionalDocument.hasOwnProperty('orgSocialRespActivity1') && data.files.additionalDocument.hasOwnProperty('orgSocialRespActivity2')){
                                        for(let i=6; i <= 7; i++){
                                            merger(data.additionalDocument[i])
                                        }
                                        this.setState({
                                            orgSocialRespActivity: this.fieldCheck(this.target)
                                        })
                                    }
                                }
                            //Living Wage
                            this.setState({
                                livingWage: this.fieldCheck(data.additionalDocument[10])
                            })   
                            //Diamond Eligibility
                            this.setState({
                                diamondEligibility: this.fieldCheck(data.additionalDocument[9])
                            })
                            //Org heighlight
                            this.setState({
                                orgHighlight: this.fieldCheck(data.additionalDocument[11])
                            })
                        }
                        break;
                    default:
                        console.log("looks like something went wrong")
                }
        })
    }
    
    render(){
        let notification = <p>Somting went wrong</p>

        if(this.props.awardType === "Gord Smith"){
            if (this.props.awardLevel === "Rising Star"){
                notification = 
                    <div>
                        <h3 style={{ color:"#000000"}}>Topic 1</h3>
                        <p>Awareness Raising <Icon icon={this.state.topic1AwarnessRaising}/></p>
                        <p>Education or Skill Building <Icon icon={this.state.topic1EduOrSkillBuilding}/></p>
                        <p>Environmental Support <Icon icon={this.state.topic1EnvSupport}/></p>
                        <p>Statement of Employer’s Commitment to Employee Wellness <Icon icon={this.state.topic1SupportivePolicy}/></p>
                        <p>Organization Highlight <Icon icon={this.state.orgHighlight}/></p>
                    </div>
            }else if (this.props.awardLevel === "Bronze Level"){
                notification = 
                        <div>
                            <h3 style={{ color:"#000000"}}>Topic 1</h3>
                            <p>Awareness Raising <Icon icon={this.state.topic1AwarnessRaising}/></p>
                            <p>Education or Skill Building <Icon icon={this.state.topic1EduOrSkillBuilding}/></p>
                            <p>Environmental Support <Icon icon={this.state.topic1EnvSupport}/></p>
                            <p>Supportive Policy <Icon icon={this.state.spportivePolicy}/></p>
                            <hr />
    
                            <h3 style={{ color:"#000000"}}>Additional Documents</h3>
                            <p>Evaluation <Icon icon={this.state.evaluation}/></p>
                            <p>Organization Highlight <Icon icon={this.state.orgHighlight}/></p>
                        </div>
            }else if(this.props.awardLevel === "Silver Level"){
                notification = 
                        <div>
                            <h3 style={{ color:"#000000"}}>Topic 1</h3>
                            <p>Awareness Raising <Icon icon={this.state.topic1AwarnessRaising}/></p>
                            <p>Education or Skill Building <Icon icon={this.state.topic1EduOrSkillBuilding}/></p>
                            <p>Environmental Support <Icon icon={this.state.topic1EnvSupport}/></p>
                            <p>Supportive Policy <Icon icon={this.state.spportivePolicy}/></p>
    
                            <hr />
    
                            <h3 style={{ color:"#000000"}}>Topic 2</h3>
                            <p>Awareness Raising <Icon icon={this.state.topic2AwarnessRaising}/></p>
                            <p>Education or Skill Building <Icon icon={this.state.topic2EduOrSkillBuilding}/></p>
                            <p>Environmental Support <Icon icon={this.state.topic2EnvSupport}/></p>
    
                            <hr />
        
                            <h3 style={{ color:"#000000"}}>Additional Documents</h3>
                            <p>Evaluation <Icon icon={this.state.evaluation}/></p>
                            <p>Comprehensive Workplace Wellness Policy <Icon icon={this.state.comprehensivePolicy}/></p>
                            <p>Organization Highlight <Icon icon={this.state.orgHighlight}/></p>
                        </div>
            }else if(this.props.awardLevel === "Gold Level" || this.props.awardLevel === "Platinum"){
                notification = 
                        <div>
                            <h3 style={{ color:"#000000"}}>Topic 1</h3>
                            <p>Awareness Raising <Icon icon={this.state.topic1AwarnessRaising}/></p>
                            <p>Education or Skill Building <Icon icon={this.state.topic1EduOrSkillBuilding}/></p>
                            <p>Environmental Support <Icon icon={this.state.topic1EnvSupport}/></p>
                            <p>Supportive Policy <Icon icon={this.state.spportivePolicy}/></p>

                            <hr />

                            <h3 style={{ color:"#000000"}}>Topic 2</h3>
                            <p>Awareness Raising <Icon icon={this.state.topic2AwarnessRaising}/></p>
                            <p>Education or Skill Building <Icon icon={this.state.topic2EduOrSkillBuilding}/></p>
                            <p>Environmental Support <Icon icon={this.state.topic2EnvSupport}/></p>
                            <p>Supportive Policy <Icon icon={this.state.spportivePolicy}/></p>

                            <hr />

                            <h3 style={{ color:"#000000"}}>Topic 3</h3>
                            <p>Awareness Raising <Icon icon={this.state.topic3AwarnessRaising}/></p>
                            <p>Education or Skill Building <Icon icon={this.state.topic3EduOrSkillBuilding}/></p>
                            <p>Environmental Support <Icon icon={this.state.topic3EnvSupport}/></p>
                            <p>Supportive Policy <Icon icon={this.state.spportivePolicy}/></p>

                            <hr />

                            <h3 style={{ color:"#000000"}}>Additional Documents</h3>
                            <p>Evaluation <Icon icon={this.state.evaluation}/></p>
                            <p>Comprehensive Workplace Wellness Policy <Icon icon={this.state.comprehensivePolicy}/></p>
                            <p>Ogranizational Social Responsibility <Icon icon={this.state.orgSocialRespActivity}/></p>
                            <p>Organization Highlight <Icon icon={this.state.orgHighlight}/></p>
                        </div>
            }else{
                notification = 
                        <div>
                            <h3 style={{ color:"#000000"}}>Topic 1</h3>
                            <p>Awareness Raising <Icon icon={this.state.topic1AwarnessRaising}/></p>
                            <p>Education or Skill Building <Icon icon={this.state.topic1EduOrSkillBuilding}/></p>
                            <p>Environmental Support <Icon icon={this.state.topic1EnvSupport}/></p>

                            <hr />

                            <h3 style={{ color:"#000000"}}>Topic 2</h3>
                            <p>Awareness Raising <Icon icon={this.state.topic2AwarnessRaising}/></p>
                            <p>Education or Skill Building <Icon icon={this.state.topic2EduOrSkillBuilding}/></p>
                            <p>Environmental Support <Icon icon={this.state.topic2EnvSupport}/></p>

                            <hr />

                            <h3 style={{ color:"#000000"}}>Topic 3</h3>
                            <p>Awareness Raising <Icon icon={this.state.topic3AwarnessRaising}/></p>
                            <p>Education or Skill Building <Icon icon={this.state.topic3EduOrSkillBuilding}/></p>
                            <p>Environmental Support <Icon icon={this.state.topic3EnvSupport}/></p>

                            <hr />

                            <h3 style={{ color:"#000000"}}>Additional Documents</h3>
                            <p>Evaluation <Icon icon={this.state.evaluation}/></p>
                            <p>Comprehensive Workplace Wellness Policy <Icon icon={this.state.comprehensivePolicy}/></p>
                            <p>Ogranizational Social Responsibility <Icon icon={this.state.orgSocialRespActivity}/></p>
                            <p>Living Wage Employer Certification <Icon icon={this.state.livingWage}/></p>
                            <p>Diamond Eligibility <Icon icon={this.state.diamondEligibility}/></p>
                            <p>Organization Highlight <Icon icon={this.state.orgHighlight}/></p>
                        </div>
            }
        }

        return(
            <div className="bp3-card bp3-elevation-2">
                <h2 style={{ color:"#000000", margin:"0px", textAlign:"center",borderBottom:"1px solid #333F48" }}>Checklist</h2>
                
                {notification}
            </div>
        )
    }
}

export default Notification;