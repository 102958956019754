import React, { Component } from 'react';

import SideMenu from '../components/Form/SideMenu';
import { Dialog, TextArea } from '@blueprintjs/core';
import Notes from '../components/Form/Notes';

import TopContainer from '../hoc/TopContainer';
import FileUpload from '../components/Form/FormItems/FileUpload';
import { base } from '../hoc/Base';
import Description from '../components/Description';
import { Redirect } from 'react-router-dom';
import  Notification  from '../components/Notification';

import * as YEAR from '../constants/year';

const header3Style = {
    marginTop: "18px"
}

class ApplicationForm extends Component {
    constructor(props) {
        super(props);
        this.handleForm = this.handleForm.bind(this);
        this.fieldChange = this.fieldChange.bind(this);

        this.state = ({
            topicTitle: '',
            topic: '',
            awareness1: '',
            awareness1Dec: '',
            awareness2: '',
            awareness2Dec: '',
            education1: '',
            education1Dec: '',
            education2: '',
            education2Dec: '',
            environmental1: '',
            environmental1Dec: '',
            environmental2: '',
            environmental2Dec: '',
            policy1: '',
            policy2: '',
            winnersBooklet: '',
            othertopic: '',
            formData: [],
            awardLevel:'',
            topic1Data:[],
            topic2Data:[],
            topic3Data:[],
            isOpen: false,
            notes:'',
            userID: '',
            year: '',
            redirect:false
        })
    }

    fieldChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    //Topic choice
    topicChoice = (e) =>{
        this.setState({
            topic: e.target.value
        })
    }

    handleForm(event) {
        event.preventDefault()
        const topicData =
            [
                { topic: this.state.topic },
                { awareness1: this.state.awareness1 },
                { awareness1Dec: this.state.awareness1Dec },
                { awareness2: this.state.awareness2 },
                { awareness2Dec: this.state.awareness2Dec },
                { education1: this.state.education1 },
                { education1Dec: this.state.education1Dec },
                { education2: this.state.education2 },
                { education2Dec: this.state.education2Dec },
                { environmental1: this.state.environmental1 },
                { environmental1Dec: this.state.environmental1Dec },
                { environmental2: this.state.environmental2 },
                { environmental2Dec: this.state.environmental2Dec },
                { policy1: this.state.policy1 },
                { policy2: this.state.policy2 },
                { othertopic: this.state.othertopic},
                { winnersBooklet:this.state.winnersBooklet},
            ]

        this.props.addFormData(topicData, this.props.topicLevel)
        this.props.addGSProgress("Saved")
        this.topicForm.reset()
        this.setState({
            isOpen:true,
        })
    }

    //toggle dialog box off
    
    toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });

    handleSubmit = () => {
        this.props.addGSProgress("Submitted")
        this.setState({
            redirect:true
        })
    }

    renderRedirect = () =>{
        if(this.state.redirect){
            return <Redirect to='/submitted' />
        }
    }

    topicTitle = (tl) => {
        var uppercase = this.props.topicLevel.charAt(0).toUpperCase() + this.props.topicLevel.slice(1);
        var levelNum = uppercase.slice(-1);
        var topLvl = uppercase.slice(0, 5) + ' ' + levelNum;

        return topLvl;
    }
    
    componentDidMount() {
        const { match: { params }} = this.props;

        this.setState({
            userID: params.uid
        })
        base.fetch(`awards/${params.uid}/reviewNotes/${this.props.topicLevel}`, {
            context: this,
            asArray: true
        }).then(data => {
            // console.log(data)
        })

        base.fetch(`awards/${params.uid}/${YEAR.CURRENT_YEAR}/GordSmith`, {
            context: this,
        }).then(data => {
            this.setState({
                formData: data,
            })

            if (this.props.topicLevel === 'topic1') {
                if ('topic1' in data){
                    this.setState({
                        topic: this.state.formData.topic1[0].topic,
                        awareness1: this.state.formData.topic1[1].awareness1,
                        awareness1Dec: this.state.formData.topic1[2].awareness1Dec,
                        awareness2: this.state.formData.topic1[3].awareness2,
                        awareness2Dec: this.state.formData.topic1[4].awareness2Dec,
                        education1: this.state.formData.topic1[5].education1,
                        education1Dec: this.state.formData.topic1[6].education1Dec,
                        education2:this.state.formData.topic1[7].education2,
                        education2Dec:this.state.formData.topic1[8].education2Dec,
                        environmental1: this.state.formData.topic1[9].environmental1,
                        environmental1Dec: this.state.formData.topic1[10].environmental1Dec,
                        environmental2: this.state.formData.topic1[11].environmental2,
                        environmental2Dec: this.state.formData.topic1[12].environmental2Dec,
                        policy1: this.state.formData.topic1[13].policy1,
                        policy2: this.state.formData.topic1[14].policy2,
                        othertopic: this.state.formData.topic1[15].othertopic,
                        winnersBooklet:this.state.formData.topic1[16].winnersBooklet,

                })}
            }else if (this.props.topicLevel === 'topic2'){
                if ('topic2' in data){
                    this.setState({
                        topic: this.state.formData.topic2[0].topic,
                        awareness1: this.state.formData.topic2[1].awareness1,
                        awareness1Dec: this.state.formData.topic2[2].awareness1Dec,
                        awareness2: this.state.formData.topic2[3].awareness2,
                        awareness2Dec: this.state.formData.topic2[4].awareness2Dec,
                        education1: this.state.formData.topic2[5].education1,
                        education1Dec: this.state.formData.topic2[6].education1Dec,
                        education2:this.state.formData.topic2[7].education2,
                        education2Dec:this.state.formData.topic2[8].education2Dec,
                        environmental1: this.state.formData.topic2[9].environmental1,
                        environmental1Dec: this.state.formData.topic2[10].environmental1Dec,
                        environmental2: this.state.formData.topic2[11].environmental2,
                        environmental2Dec: this.state.formData.topic2[12].environmental2Dec,
                        policy1: this.state.formData.topic2[13].policy1,
                        policy2: this.state.formData.topic2[14].policy2,
                        othertopic: this.state.formData.topic2[15].othertopic,

                })}
            }else if (this.props.topicLevel === 'topic3'){
                if ('topic3' in data){
                    this.setState({
                        topic: this.state.formData.topic3[0].topic,
                        awareness1: this.state.formData.topic3[1].awareness1,
                        awareness1Dec: this.state.formData.topic3[2].awareness1Dec,
                        awareness2: this.state.formData.topic3[3].awareness2,
                        awareness2Dec: this.state.formData.topic3[4].awareness2Dec,
                        education1: this.state.formData.topic3[5].education1,
                        education1Dec: this.state.formData.topic3[6].education1Dec,
                        education2:this.state.formData.topic3[7].education2,
                        education2Dec:this.state.formData.topic3[8].education2Dec,
                        environmental1: this.state.formData.topic3[9].environmental1,
                        environmental1Dec: this.state.formData.topic3[10].environmental1Dec,
                        environmental2: this.state.formData.topic3[11].environmental2,
                        environmental2Dec: this.state.formData.topic3[12].environmental2Dec,
                        policy1: this.state.formData.topic3[13].policy1,
                        policy2: this.state.formData.topic3[14].policy2,
                        othertopic: this.state.formData.topic3[15].othertopic,
                })}
            }
        })
        
    }

    render() {
        return (
            <div className="form">
                <div>
                    {this.renderRedirect()}
                </div>
                <Dialog
                    isOpen={this.state.isOpen}
                    onClose={this.toggleDialog}
                    title={this.props.topicLevel}>

                    <div className="bp3-dialog-body">
                        <h3>Application Saved</h3>
                        <p> Remember you can always come back and edit your information.</p>
                        {this.props.awardLevel === 'Rising Star' ?
                        <div>
                        <button onClick={()=>this.handleSubmit()}
                        className="bp3-button bp3-intent-success bp3-fill"><p>Submit Application</p></button>
                    </div>
                    :null}
                    </div>
                </Dialog>

                <div className="side-menu">
                    <SideMenu
                        awardType={this.props.awardType}
                        awardLevel={this.props.awardLevel}
                        topicLevel={this.props.topicLevel}
                        user={this.props.user}
                        />
                    <Notification 
                        awardType={this.props.awardType}
                        awardLevel={this.props.awardLevel}
                        user={this.props.user}
                    />

                </div>

                <div className="application-form">
                    <TopContainer>
                        <div className="wtext">
                            <h2 style={{ margin:"0", fontSize: "50px", color: "#FFFFFF", marginBottom: "10px" }}>Wellness {this.topicTitle(this.props.topicLevel)}</h2>

                            {this.props.awardLevel === "Rising Star" ?
                           <div>
                               <p> Rising Star applicants must complete ONE awareness raising, education/skill-building, OR environmental support wellness activity between July 20, 2018 and August 30, 2019. Refer to our <a href="https://wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">website</a> for example wellness activities for mental health, healthy eating, physical activity, preventing injury, infection control or substance use. Refer to the Table 1 at the end of the Awards Application Instructions for examples for appropriate descriptions and evidence of wellness activities.</p>
                                <p>* Remember to Save before leaving this page to avoid loosing any information.  </p>
                           </div>
                            :null}

                            {this.props.awardLevel === "Bronze Level 1" ?
                            <div>
                                <p>As you complete this online application, please keep in mind that Bronze Level 1 applicants must complete all fields in the Topic 1 and Additional Documents pages.  Please refer to <a href="https://wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">wechu.org/workplace-awards</a> for a full list of criteria required for this level.  Refer to the <strong>Awards Application Instructions</strong>, that were emailed with your login information, for more detailed directions and examples for appropriate descriptions and evidence.  If you require assistance, please email cdip@wechu.org or call 519-258-2146 ext. 3200.</p>
                                <p>* Remember to Save each page before moving to the next section to avoid loosing any information. </p>
                            </div>
                            :null}

                            {this.props.awardLevel === "Bronze Level 2" ?
                            <div>
                                <p>As you complete this online application, please keep in mind that Bronze Level 2 applicants must complete all fields in the Topic 1 and Additional Documents pages.  Please refer to <a href="https://wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">wechu.org/workplace-awards</a> for a full list of criteria required for this level.  Refer to the <strong>Awards Application Instructions</strong>, that were emailed with your login information, for more detailed directions and examples for appropriate descriptions and evidence.  If you require assistance, please email cdip@wechu.org or call 519-258-2146 ext. 3200.</p>
                                <p>* Remember to Save each page before moving to the next section to avoid loosing any information. </p>
                            </div>
                            :null}

                            {this.props.awardLevel === "Silver Level 1" ?
                            <div>
                                <p>As you complete this online application, please keep in mind that Silver Level 1 applicants must complete all fields in the Topic 1, Topic 2, and Additional Documents pages.  Please refer to <a href="https://wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">wechu.org/workplace-awards</a> for a full list of criteria required for this level.  Refer to the <strong>Awards Application Instructions</strong>, that were emailed with your login information, for more detailed directions and examples for appropriate descriptions and evidence.  If you require assistance, please email cdip@wechu.org or call 519-258-2146 ext. 3200.</p>
                                <p>* Remember to Save each page before moving to the next section to avoid loosing any information. </p>
                            </div>
                            :null}

                            {this.props.awardLevel === "Silver Level 2" ?
                            <div>
                                <p>As you complete this online application, please keep in mind that Silver Level 2 applicants must complete all fields in the Topic 1, Topic 2,  and Additional Documents pages.  Please refer to <a href="https://wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">wechu.org/workplace-awards</a> for a full list of criteria required for this level.  Refer to the <strong>Awards Application Instructions</strong>, that were emailed with your login information, for more detailed directions and examples for appropriate descriptions and evidence.  If you require assistance, please email cdip@wechu.org or call 519-258-2146 ext. 3200.</p>
                                <p>* Remember to Save each page before moving to the next section to avoid loosing any information. </p>
                            </div>
                            :null}

                            {this.props.awardLevel === "Gold Level 1" ?
                            <div>
                                <p>As you complete this online application, please keep in mind that Gold Level 1 applicants must complete all fields in the Topic 1, Topic 2, Topic 3 and Additional Documents pages.  Please refer to <a href="https://wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">wechu.org/workplace-awards</a> for a full list of criteria required for this level.  Refer to the <strong>Awards Application Instructions</strong>, that were emailed with your login information, for more detailed directions and examples for appropriate descriptions and evidence.  If you require assistance, please email cdip@wechu.org or call 519-258-2146 ext. 3200.</p>
                                <p>* Remember to Save each page before moving to the next section to avoid loosing any information. </p>
                            </div>
                            :null}

                            {this.props.awardLevel === "Gold Level 2" ?
                            <div>
                                <p>As you complete this online application, please keep in mind that Gold Level 2 applicants must complete all fields in the Topic 1, Topic 2, Topic 3 and Additional Documents pages.  Please refer to <a href="https://wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">wechu.org/workplace-awards</a> for a full list of criteria required for this level.  Refer to the <strong>Awards Application Instructions</strong>, that were emailed with your login information, for more detailed directions and examples for appropriate descriptions and evidence.  If you require assistance, please email cdip@wechu.org or call 519-258-2146 ext. 3200.</p>
                                <p>* Remember to Save each page before moving to the next section to avoid loosing any information. </p>
                            </div>
                            :null}

                            {this.props.awardLevel === "Platinum" ?
                            <div>
                                <p>As you complete this online application, please keep in mind that Platinum applicants must complete all fields in the Topic 1, Topic 2, Topic 3 and Additional Documents pages.  Please refer to <a href="https://wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">wechu.org/workplace-awards</a> for a full list of criteria required for this level.  Refer to the <strong>Awards Application Instructions</strong>, that were emailed with your login information, for more detailed directions and examples for appropriate descriptions and evidence.  If you require assistance, please email cdip@wechu.org or call 519-258-2146 ext. 3200.</p>
                                <p>* Remember to Save each page before moving to the next section to avoid loosing any information. </p>
                            </div>
                            :null}

                            {this.props.awardLevel === "Diamond" ?
                            <div>
                                <p>As you complete this online application, please keep in mind that Diamond applicants must complete all fields in the Topic 1, Topic 2, Topic 3 and Additional Documents pages.  Please refer to <a href="https://wechu.org/workplace-wellness-programs/gord-smith-healthy-workplace-bike-friendly-workplace-award" target="_blank" rel="noopener noreferrer">wechu.org/workplace-awards</a> for a full list of criteria required for this level.  Refer to the <strong>Awards Application Instructions</strong>, that were emailed with your login information, for more detailed directions and examples for appropriate descriptions and evidence.  If you require assistance, please email cdip@wechu.org or call 519-258-2146 ext. 3200.</p>
                                <p>* Remember to Save each page before moving to the next section to avoid loosing any information. </p>
                            </div>
                            :null}
                        </div>

                        {/* {this.props.awardLevel === "Rising Star" ? <div></div> :
                           <div><Button onClick={this.handleLoad} role="button" className="bp3-button bp3-intent-primary" tabIndex="0">Load 2018 {this.props.topicLevel}</Button></div>
                        }
                     */}

                        <form onSubmit={(event) => this.handleForm(event)} ref={(form) => this.topicForm = form}>

                            {/* ----------------------wellness Topoic---------------------- */}
                            <div className="bp3-card silver">

                                <label className="bp3-label" style={{textAlign:"center", color: "#FFFFFF", fontSize: "25px"}}><strong>Select one wellness topic that you have focused on throughout the year</strong></label>

                                <div className="bp3-select bp3-large bp3-fill">
                                    <select value={this.state.topic}  onChange={(e) => this.topicChoice(e)}>
                                        <option value="select">- Select -</option>
                                        <option value="Mental-Health-Promotion">Mental Health Promotion</option>
                                        <option value="Healthy-Eating">Healthy Eating</option>
                                        <option value="Physical-Activity">Physical Activity</option>
                                        <option value="Preventing-Injury">Preventing Injury</option>
                                        <option value="Infection-Control-and-Prevention">Infection Control and Prevention</option>
                                        <option value="Substance-Use">Substance Use</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                {this.state.topic === 'Other' || this.state.year === '2018' ? 

                                <div>
                                    <label className="bp3-label">Please enter a wellness topic </label>
                                    <input
                                        style={{ height: "70px", fontSize: "30px" }}
                                        className="bp3-input bp3-large"
                                        type="Text"
                                        name="othertopic"
                                        ref={(input) => { this.topicInput = input }}
                                        onChange={e => this.fieldChange(e)}
                                        placeholder="Enter the topic"
                                        value={this.state.year === '2018' ? this.state.topic : this.state.othertopic}
                                    />
                                </div>
                                :null}
                            </div>

                            <h3 style={header3Style}>Activities for the following health promotion strategies:</h3>

                            {/* ----------------------Awarness Rising---------------------- */}
                            <div className="bp3-card silver">
                                <h5>Awareness Raising</h5>
                                <Description 
                                    panelDes="Awareness Raising"
                                    wellnesstopic={this.state.topic}/>

                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Name of activity</label>
                                    <div>
                                        <input
                                            className="bp3-input"
                                            type="Text"
                                            name="awareness1"
                                            ref={(input) => { this.awarnessRising1 = input }}
                                            onChange={e => this.fieldChange(e)}
                                            placeholder="Name of activity"
                                            value={this.state.awareness1} />
                                    </div>

                                    <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                    <div>
                                        <TextArea
                                            name="awareness1Dec"
                                            style={{ width: "100%", height: "100px" }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.awareness1Dec}
                                        />
                                    </div>

                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                        <FileUpload
                                            awardType='GordSmith'
                                            getUploadFileName={this.getUploadFileName}
                                            uploadPane="filesForAwareness1"
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user} />
                                    </div>


                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='AwarenessRising1'
                                        />
                                </div>

                                {/* if award level is gold level 2 or higher show form */}
                                {this.props.awardLevel === 'Gold Level 2' || this.props.awardLevel === 'Platinum' || this.props.awardLevel === 'Diamond' ?
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of activity </label>
                                        <div>
                                            <input
                                                className="bp3-input"
                                                type="Text"
                                                name="awareness2"
                                                ref={(input) => { this.awarnessRising2 = input }}
                                                onChange={e => this.fieldChange(e)}
                                                placeholder="Name of activity"
                                                value={this.state.awareness2} />
                                        </div>

                                        <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                        <div>
                                            <TextArea
                                                name="awareness2Dec"
                                                style={{ width: "100%", height: "100px" }}
                                                onChange={e => this.fieldChange(e)}
                                                value={this.state.awareness2Dec}
                                            />
                                        </div>

                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                            <FileUpload
                                                
                                                awardType='GordSmith' 
                                                getUploadFileName={this.getUploadFileName}
                                                uploadPane="filesForAwareness2"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />

                                        </div>

                                        <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='AwarenessRising2'
                                        />
                                    </div> : null}
                            </div>

                            {/* ----------------------Education or Skill Building---------------------- */}
                            <div className="bp3-card silver">
                                <Description 
                                    panelDes="Education or Skill Building"
                                    wellnesstopic={this.state.topic}/>
                                <h5>Education or Skill Building</h5>


                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Name of activity </label>
                                    <div>
                                        <input
                                            className="bp3-input"
                                            type="Text"
                                            name="education1"
                                            ref={(input) => { this.education1 = input }}
                                            onChange={e => this.fieldChange(e)}
                                            placeholder="Name of activity"
                                            value={this.state.education1}
                                        />
                                    </div>

                                    <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                    <div>
                                        <TextArea
                                            name="education1Dec"
                                            style={{ width: "100%", height: "100px" }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.education1Dec}
                                        />
                                    </div>

                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                        <FileUpload
                                            
                                            awardType='GordSmith'
                                            getUploadFileName={this.getUploadFileName}
                                            uploadPane="filesForEducation1"
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user} />

                                    </div>

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='Education1'
                                        />
                                </div>

                                {/* if award level is gold level 2 or higher show form */}
                                {this.props.awardLevel === 'Gold Level 2' || this.props.awardLevel === 'Platinum' || this.props.awardLevel === 'Diamond' ?
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of activity </label>
                                        <div>
                                            <input
                                                className="bp3-input"
                                                type="Text"
                                                name="education2"
                                                ref={(input) => { this.education2 = input }}
                                                onChange={e => this.fieldChange(e)}
                                                placeholder="Name of activity"
                                                value={this.state.education2}
                                            />
                                        </div>

                                        <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                        <div>
                                            <TextArea
                                                name="education2Dec"
                                                style={{ width: "100%", height: "100px" }}
                                                onChange={e => this.fieldChange(e)}
                                                value={this.state.education2Dec}
                                            />
                                        </div>

                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                            <FileUpload
                                                
                                                awardType='GordSmith'
                                                getUploadFileName={this.getUploadFileName}
                                                uploadPane="filesForEducation2"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />

                                        </div>

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='Education2'
                                        />
                                    </div> : null}
                            </div>

                            {/* ----------------------Environmental Support---------------------- */}
                            <div className="bp3-card silver">
                                <Description 
                                    panelDes="Environmental Support"
                                    wellnesstopic={this.state.topic}/>
                                <h5>Environmental Support</h5>


                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Name of activity</label>
                                    <div>
                                        <input
                                            className="bp3-input"
                                            type="Text"
                                            name="environmental1"
                                            ref={(input) => { this.environmental1 = input }}
                                            onChange={e => this.fieldChange(e)}
                                            placeholder="Name of activity"
                                            value={this.state.environmental1}
                                        />
                                    </div>

                                    <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                    <div>
                                        <TextArea
                                            name="environmental1Dec"
                                            style={{ width: "100%", height: "100px" }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.environmental1Dec}
                                        />
                                    </div>

                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                        <FileUpload
                                            
                                            awardType='GordSmith'
                                            getUploadFileName={this.getUploadFileName}
                                            uploadPane="filesForEnvironmental1"
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user} />

                                    </div>

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='Environmental1'
                                        />
                                </div>

                                {/* if award level is gold level 2 or higher show form */}
                                {this.props.awardLevel === 'Gold Level 2' || this.props.awardLevel === 'Platinum' || this.props.awardLevel === 'Diamond' ?
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of activity</label>
                                        <div>
                                            <input
                                                className="bp3-input"
                                                type="Text"
                                                name="environmental2"
                                                ref={(input) => { this.environmental2 = input }}
                                                onChange={e => this.fieldChange(e)}
                                                placeholder="Name of activity"
                                                value={this.state.environmental2}
                                            />
                                        </div>

                                        <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                        <div>
                                            <TextArea
                                                name="environmental2Dec"
                                                style={{ width: "100%", height: "100px" }}
                                                onChange={e => this.fieldChange(e)}
                                                value={this.state.environmental2Dec}
                                            />
                                        </div>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                            <FileUpload
                                                
                                                awardType='GordSmith'
                                                getUploadFileName={this.getUploadFileName}
                                                uploadPane="filesForEnvironmental2"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />

                                        </div>

                                        <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='Environmental2'
                                        />
                                    </div> : null}
                            </div>

                            {/* ----------------------Supportive Policy Requirement---------------------- */}
                            {this.props.topicLevel === 'topic2' && this.props.awardLevel === "Silver Level" ? null :
                            <div>
                            {this.props.awardLevel === 'Bronze Level' || this.props.awardLevel === 'Silver Level' ||  this.props.awardLevel === 'Gold Level' || this.props.awardLevel === 'Platinum' || this.props.awardLevel === 'Diamond' ?
                                <div className="bp3-card silver">
                                    <Description 
                                    panelDes="Supportive Policy"
                                    wellnesstopic={this.state.topic}/>
                                    <h5>Supportive Policy</h5>

                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of policy specific to the wellness topic chosen</label>
                                        <div>
                                            <input
                                                className="bp3-input"
                                                type="Text"
                                                name="policy1"
                                                ref={(input) => { this.policy1 = input }}
                                                onChange={e => this.fieldChange(e)}
                                                placeholder="Name of policy"
                                                value={this.state.policy1}
                                            />
                                        </div>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach Policy</label>
                                            <FileUpload
                                                year={this.state.year}
                                                awardType='GordSmith'
                                                getUploadFileName={this.getUploadFileName}
                                                uploadPane="filesForPolicy1"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />

                                        </div>

                                        <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='Policy1'
                                            />
                                    </div>
                                    {/* {this.props.awardLevel === 'Platinum' || this.props.awardLevel === 'Diamond' ?
                                        <div className="bp3-card bp3-elevation-2">
                                            <label className="bp3-label">Name of Policy</label>
                                            <div>
                                                <input
                                                    className="bp3-input"
                                                    type="Text"
                                                    name="policy2"
                                                    ref={(input) => { this.policy2 = input }}
                                                    onChange={e => this.fieldChange(e)}
                                                    placeholder="Name of policy"
                                                    value={this.state.policy2}
                                                />
                                            </div>
                                            <div>
                                                <label style={{ marginTop: '18px' }} className="bp3-label">Attach Policy</label>
                                                <FileUpload
                                                    year={this.state.year}
                                                    awardType='GordSmith'
                                                    getUploadFileName={this.getUploadFileName}
                                                    uploadPane="filesForPolicy2"
                                                    topicLevel={this.props.topicLevel}
                                                    user={this.props.user} />

                                            </div>

                                            <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='Policy2'
                                            />
                                        </div> : null} */}
                                </div> : null}
                            </div>
                            }

                            {/* ----------------------Statement of Employer's commitment---------------------- */}
                            {this.props.awardLevel === 'Rising Star' ?
                            <div className="bp3-card silver">
                                    <Description 
                                    panelDes="statement"
                                    wellnesstopic={this.state.topic}/>
                                    <h5>Statement of Employer’s Commitment to Employee Wellness</h5>


                                    <p>Please provide a copy of your Statement of Commitment to Employee Wellness</p>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach Statement</label>
                                            <FileUpload
                                                
                                                awardType='GordSmith'
                                                getUploadFileName={this.getUploadFileName}
                                                uploadPane="statement"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />

                                        </div>

                                        <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='statement'
                                            />
                                    </div> : null}

                            {/* ----------------------Orgainzation heighligt---------------------- */}        
                            {this.props.awardLevel === 'Rising Star' ?
                            <div className="bp3-card silver">
                                <h5>Organization Highlight</h5>
                                <p>Please provide a brief write-up that describes one of the wellness activities included in your application  (200-word maximum). This will be read from the podium at the Awards Ceremony  and displayed on the Windsor-Essex County Health Unit’s website.</p>
                                <div className="bp3-card bp3-elevation-2">
                                <TextArea
                                    name="winnersBooklet"
                                    style={{ width: "100%", height: "250px" }}
                                    onChange={e => this.fieldChange(e)}
                                    value={this.state.winnersBooklet}
                                    />
                                </div>

                                <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='booklet'
                                            />
                            </div> : null}

                            
                                <input
                                    style={{ right:"50px" , width: "200px", position:"fixed", bottom:"30px" }}
                                    type="submit"
                                    className="bp3-button bp3-intent-primary bp3-large"
                                    value="Save Topic"></input>
                            
                        </form>
                    </TopContainer>
                </div>
            </div>
        );
    }
}

export default ApplicationForm;
