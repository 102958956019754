import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TopContainer from './../hoc/TopContainer';
import { Button } from '@blueprintjs/core';
import { Redirect } from 'react-router-dom';

import { base } from './../hoc/Base';

const awardsStyle={
  maxWidth: "500px",
  margin:"0 auto",
}

class Awards extends Component {
  constructor(props){
    super(props)
    this.state = ({
      orgName:'',
      showInput: false,
      isOpen: false,
      redirect: false,
      redirectReview:false,
      letstart:true,
      roll:'',
    })
  }

  fieldChange(e) {
    this.setState({
        [e.target.name]: e.target.value,
        letstart:false
    });
    if (e.target.value === ''){
      this.setState({
        letstart:true
      })
    }
  }

  addOrgName(){
      //check if orgName is filled
      if (this.state.orgName === ''){
        this.setState({
          isOpen: true
          })
        } else{
          base.post(`awards/${this.props.user}/orgName/name`,{
          data: this.state.orgName
        }).then(()=>{
          this.setState({
            redirect: true
          })
        })
      }
    }

  componentDidMount(){
    base.fetch('admins',{
        context:this,
        asArray:true,
      }).then(data => {
          this.setState({roll:'reviewer'})
      }).catch(error => {
          this.setState({roll:''})
      })


      base.fetch(`awards/${this.props.user}/orgName`,{
        context: this,
        asArray:true
      }).then(data =>{
        if (data.length !== 0){
          this.setState({
            orgName: data,
          })
        }else{
          this.setState({
            orgName: data,
            showInput: true
          })
        }
      })
    }

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/award-type" />
    }else if(this.state.roll === 'reviewer'){
      return <Redirect to="/reviewer" />
    }else{
    return (
      <TopContainer>
      
        <div style={awardsStyle} className="awards">
          <h1 style={{ textAlign: "center" }}>GORD SMITH<br /> HEALTHY WORKPLACE AWARDS</h1>
  
          {this.props.reviewer  ? 
              <Link  style={{width: "100%"}} className="pb3-button bp3-intent-success" to="/admin">View Applications</Link>
              :
              (<div className="bp3-card bp3-elevation-0">
            <p>The Windsor-Essex County Health Unit, and the Working Toward Wellness (WTW) Workplace Wellness Committee are pleased to present The Gord Smith Healthy Workplace Awards</p>
  

            {this.state.showInput ? 
            
            <div className="bp3-card">
                  <input
                      style={{ height: "70px", fontSize: "30px" }}
                      className="bp3-input bp3-large"
                      type="Text"
                      name="orgName"
                      onChange={e => this.fieldChange(e)}
                      placeholder="Enter Organization Name"
                      value={this.state.orgName}
                  />
              </div>

              : 

              <div><h2 style={{textAlign:"center", color:"#00596f", fontSize: "42px", margin: "18px 0"}}>{this.state.orgName}</h2></div>
            }

            
              {this.state.showInput ? 
              <Button disabled={this.state.letstart} onClick={() => this.addOrgName()} style={{width: "100%"}} className="bp3-button bp3-intent-success" >Let's Start</Button>
                : 
                <Link  style={{width: "100%"}} className="bp3-button bp3-intent-success" to="/award-GS-level">Let's Start</Link>}
            
            
          </div>)
            }
        </div>
      </TopContainer>
    );
          }
  }
}

export default Awards;
