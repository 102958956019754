import React, {useContext} from 'react';

import {AuthContext} from "./../hoc/Auth";
import { Link } from 'react-router-dom';

import HWA_jumbotron from '../assets/images/HWA_jumbotron.png';

const Index = () => {   
  //get current user from AuthContext
  const {currentUser} = useContext(AuthContext);
  return(
      <div className="container">
        <div className="jumbotron">
          <img src={HWA_jumbotron} alt="Gord Smith Healthy Workplace Awards" />
  
          <div className="wtext">
            <p>Gord Smith Healthy Workplace Award recipients demonstrate a commitment to comprehensive wellness programming for employees, and ideally, for family members and retirees. Employers promote health using awareness raising, education or skill-building, environmental supports, and supportive policies.</p>
      
            <p>The Awards are available to all workplaces in Windsor and Essex County. Workplaces receive a plaque, a custom e-signature, and recognition at an awards ceremony.</p>
            {currentUser ?  <Link className="bp3-button" to="/awards">ENTER</Link> : null }
          </div>
        </div>
      </div>
      );
}


export default Index;
