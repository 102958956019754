import React, { Component } from 'react';

import TopContainer from '../hoc/TopContainer';
import FileUpload from '../components/Form/FormItems/FileUpload';
import { base } from '../hoc/Base';
import { Dialog, Radio, RadioGroup, TextArea } from '@blueprintjs/core';
import Notes from '../components/Form/Notes';
import Description from '../components/Description';
import SideMenu from '../components/Form/SideMenu';
import { Redirect } from 'react-router-dom';

import * as YEAR from '../constants/year';


class ApplicationForm extends Component {
    constructor(props) {
        super(props);
        this.handleForm = this.handleForm.bind(this);
        this.fieldChange = this.fieldChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this)
        this.state = ({
            isOpen: false,
            policy: '',
            promotion: '',
            promotionDec: '',
            education: '',
            educationDec: '',
            environmental: '',
            incentives: '',
            policyStatment: '',
            radioValue:'',
            winnersBooklet: '',
            formData: [],
            redirect: false,
            year: '',
        })
    }

    fieldChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    //handle raido 
    handleRadioChange(e) {
        this.setState({
            radioValue: e.target.value
        })
    }

    //Load application from 2018
    // handleLoad(){

    //     base.fetch(`awards/${this.props.user}/BikeFriendly`, {
    //         context: this,
    //     }).then(data => {
    //         this.setState({
    //             formData: data,
    //             year: '2018'
    //         })
    //         if ('bikeFriendly' in data){
    //             this.setState({
    //                 policy: this.state.formData.bikeFriendly[0].policy,
    //                 promotion: this.state.formData.bikeFriendly[1].promotion,
    //                 promotionDec: this.state.formData.bikeFriendly[2].promotionDec,
    //                 education: this.state.formData.bikeFriendly[3].education,
    //                 educationDec: this.state.formData.bikeFriendly[4].educationDec,
    //                 environmental: this.state.formData.bikeFriendly[5].environmental,
    //                 incentives: this.state.formData.bikeFriendly[6].incentives,
    //                 policyStatment: this.state.formData.bikeFriendly[7].policyStatment,
    //                 radioValue: this.state.formData.bikeFriendly[8].radioValue,
    //                 winnersBooklet:this.state.formData.bikeFriendly[9].winnersBooklet
    //         })
    //     }
    //     })
    // }


    instructions = (level) =>{
        switch(level){
            case 'Bronze Level':
                return <h3>Describe how TWO Bike Friendly categories were fulfilled at your workplace:</h3>;
            case 'Silver Level':
                return <h3>Describe how THREE Bike Friendly categories were fulfilled at your workplace:</h3>;
            case 'Gold Level':
                return <h3>Describe how FOUR Bike Friendly categories were fulfilled at your workplace:</h3>
            case 'Platinum':
                return <h3>Describe how ALL FIVE Friendly categories were fulfilled at your workplace:</h3>;
            case 'Platinum Plus':
                return <h3>Describe how ALL FIVE Bike Friendly categories were fulfilled at your workplace, provide a ‘pro’ bicycle policy statement and evidence that cycling is supported at all organizational work sites:</h3>;
            default:
                return null;
        }
    }

    handleForm(event) {
        event.preventDefault()
        const topicData =
            [
                { policy: this.state.policy },
                { promotion: this.state.promotion },
                { promotionDec: this.state.promotionDec },
                { education: this.state.education },
                { educationDec: this.state.educationDec },
                { environmental: this.state.environmental },
                { incentives: this.state.incentives },
                { policyStatment: this.state.policyStatment },
                { radioValue: this.state.radioValue},
                { winnersBooklet: this.state.winnersBooklet}

            ]

        this.props.addFormData(topicData, this.props.topicLevel)
        this.topicForm.reset()
        this.setState({
            isOpen:true
        })
    }

    //toggle dialog box off
    toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });

    handleSubmit = () => {
        this.props.addBFProgress("Submitted")
        this.setState({
            redirect:true
        })
    }

    renderRedirect = () =>{
        if(this.state.redirect){
            return <Redirect to='/submitted' />
        }
    }

    componentDidMount() {
        const { match: {params} } = this.props;

        base.fetch(`awards/${params.uid}/${YEAR.CURRENT_YEAR}/BikeFriendly`, {
            context: this,
        }).then(data => {
            this.setState({
                formData: data
            })
            if ('bikeFriendly' in data){
                    this.setState({
                        policy: this.state.formData.bikeFriendly[0].policy,
                        promotion: this.state.formData.bikeFriendly[1].promotion,
                        promotionDec: this.state.formData.bikeFriendly[2].promotionDec,
                        education: this.state.formData.bikeFriendly[3].education,
                        educationDec: this.state.formData.bikeFriendly[4].educationDec,
                        environmental: this.state.formData.bikeFriendly[5].environmental,
                        incentives: this.state.formData.bikeFriendly[6].incentives,
                        policyStatment: this.state.formData.bikeFriendly[7].policyStatment,
                        radioValue: this.state.formData.bikeFriendly[8].radioValue,
                        winnersBooklet:this.state.formData.bikeFriendly[9].winnersBooklet
                })
            }
        })
    }

    render() {
        return (
            <div className="form">
 <div>
                    {this.renderRedirect()}
                </div>
                <Dialog
                    isOpen={this.state.isOpen}
                    onClose={this.toggleDialog}
                    title={this.props.topicLevel}>
                    <div className="bp3-dialog-body">
                        <h3>Application Saved</h3>
                        <p> Remember you can always come back and edit your information.</p>
                   
                    <div>
                            <button onClick={()=>this.handleSubmit()}
                            className="bp3-button bp3-intent-success bp3-fill"><p>Submit Application</p></button>
                        </div>
                     </div>
                </Dialog>

                <div className="side-menu">
                    <SideMenu
                        awardType={this.props.awardType}
                        awardLevel={this.props.awardLevel}
                        user={this.props.user.uid} />
                </div>

                <div className="application-form">
                    <TopContainer>
                    
                        <h1>{this.props.awardLevel}</h1>
                            {this.instructions(this.props.awardLevel)}
                            <p>If you require assistance, please email <a href="mailto:cdip@wechu.org">cdip@wechu.org</a> or call 519-258-2146 ext. 3200.</p>
                           
                            {/* <div><Button onClick={()=> this.handleLoad()} role="button" className="bp3-button bp3-intent-primary" tabIndex="0">Load 2018 Application</Button></div> */}
                    
                        <form onSubmit={(event) => this.handleForm(event)} ref={(form) => this.topicForm = form}>

                            {/* ----------------------Promotion---------------------- */}
                            <div className="bp3-card silver">
                                <Description 
                                    panelDes="Promotion"/>
                                <h5>Promotion</h5>

                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Type of promotion </label>
                                    <div>
                                        <input
                                            className="bp3-input"
                                            type="Text"
                                            name="promotion"
                                            ref={(input) => { this.promotion = input }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.promotion}
                                        />
                                    </div>

                                    <label className="bp3-label">Description of how activity was conducted</label>
                                    <div>
                                        <TextArea
                                            name="promotionDec"
                                            style={{ width: "100%", height: "100px" }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.promotionDec}
                                        />
                                    </div>

                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                        <FileUpload
                                            awardType='BikeFriendly'
                                            getUploadFileName={this.getUploadFileName}
                                            uploadPane="promotion"
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user} />
                                            

                                    </div>

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='promotion'
                                        />
                                </div>
                            </div>

                            {/* ----------------------Education---------------------- */}
                            <div className="bp3-card silver">
                                <Description 
                                    panelDes="Education"/>
                                <h5>Education</h5>

                                <div className="bp3-card bp3-elevation-2">
                                    
                                    <label className="bp3-label">Name of Activity </label>
                                    <div>
                                        <input
                                            className="bp3-input"
                                            type="Text"
                                            name="education"
                                            ref={(input) => { this.education = input }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.education}
                                        />
                                    </div>

                                    <label className="bp3-label">How education was provided </label>
                                    <div>
                                        <TextArea
                                            name="educationDec"
                                            style={{ width: "100%", height: "100px" }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.educationDec}
                                        />
                                    </div>

                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                        <FileUpload
                                            awardType='BikeFriendly'
                                            getUploadFileName={this.getUploadFileName}
                                            uploadPane="education"
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user} />

                                    </div>

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='education'
                                        />
                                </div>
                            </div>

                            {/* ----------------------Environmental Supports---------------------- */}
                            <div className="bp3-card silver">
                                <Description 
                                    panelDes="Environmental Supports BF"/>
                                <h5>Environmental Supports</h5>

                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Type of environmental support provided </label>
                                    <div>
                                        <input
                                            className="bp3-input"
                                            type="Text"
                                            name="environmental"
                                            ref={(input) => { this.environmental = input }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.environmental}
                                        />
                                    </div>
                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                        <FileUpload
                                            year={this.state.year}
                                            awardType='BikeFriendly'
                                            getUploadFileName={this.getUploadFileName}
                                            uploadPane="environmental"
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user} />

                                    </div>

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='environmental'
                                        />
                                </div>
                            </div>

                            {/* ----------------------Incentives---------------------- */}
                            <div className="bp3-card silver">
                                <Description 
                                    panelDes="Incentives"/>
                                <h5>Incentives</h5>

                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Type of incentives provided</label>
                                    <div>
                                        <input
                                            className="bp3-input"
                                            type="Text"
                                            name="incentives"
                                            ref={(input) => { this.incentives = input }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.incentives}
                                        />
                                    </div>
                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                        <FileUpload
                                            awardType='BikeFriendly'
                                            getUploadFileName={this.getUploadFileName}
                                            uploadPane="incentives"
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user} />

                                    </div>

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='incentives'
                                        />
                                </div>
                            </div>

                             {/* ----------------------Policy---------------------- */}
                             <div className="bp3-card silver">
                                
                                <Description 
                                    panelDes="Policy"/>
                                <h5>Policy</h5>

                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Name of policy</label>
                                    <div>
                                        <input
                                            className="bp3-input"
                                            type="Text"
                                            name="policy"
                                            ref={(input) => { this.policy = input }}
                                            onChange={e => this.fieldChange(e)}
                                            value={this.state.policy} />
                                    </div>
                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach policy</label>

                                        <FileUpload
                                            year={this.state.year}
                                            awardType='BikeFriendly'
                                            getUploadFileName={this.getUploadFileName}
                                            uploadPane="policy"
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user} />
                                    </div>

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='policy'
                                        />
                                </div>
                            </div>

                            {/* ----------------------Bicycle Policy Statement---------------------- */}        
                                {this.props.awardLevel === 'Platinum Plus' ?
                                <div className="bp3-card silver">
                                    <Description 
                                        panelDes="Pro Bicycle Policy Statement"/>
                                    <h5>Provide a 'Pro' Bicycle Policy Statement</h5>
                                   
                                        <div>
                                            
                                            <FileUpload
                                                year={this.state.year}
                                                awardType='BikeFriendly'
                                                getUploadFileName={this.getUploadFileName}
                                                uploadPane="pro"
                                                topicLevel={this.props.topicLevel}
                                                user={this.props.user} />
    
                                        </div>
                                        <Notes 
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user}
                                            panel='pro'
                                            />
                                    
                                </div> : null
                                }



                            {/* ----------------------Multiple Site---------------------- */}
                            { this.props.awardLevel === 'Platinum Plus' ?
                                <div className="bp3-card silver">
                                    <h5>Multiple Site</h5>
                                    <div className="bp3-card bp3-elevation-2">
                                    <label style={{ marginTop: '18px' }} className="bp3-label">Provide evidence that all worksites are able to support employees cycling to their place of work</label>
                                    <RadioGroup

                                        name="group"
                                        onChange={this.handleRadioChange}
                                        selectedValue={this.state.radioValue}>

                                        <Radio 
                                            className="bp3-control bp3-radio bp3-large" 
                                            label=" My workplace does not have multiple sites." 
                                            value="one" />

                                        <Radio 
                                            className="bp3-control bp3-radio bp3-large" 
                                            label="My workplace has multiple sites" 
                                            value="two" />

                                    </RadioGroup>

                                    <div>
                                        <FileUpload
                                            year={this.state.year}
                                            awardType='BikeFriendly'
                                            getUploadFileName={this.getUploadFileName}
                                            uploadPane="multi-site"
                                            topicLevel={this.props.topicLevel}
                                            user={this.props.user} />

                                    </div>

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='multi-site'
                                        />
                                    </div>
                                </div>: null
                            }



                            {/* ----------------------Winners's Booklet---------------------- */}        
                            <div className="bp3-card silver">
                                <h5>Organization Highlight</h5>
                                <p>Please provide a brief write-up that describes one of the wellness activities included in your application (200-word maximum). This will be read from the podium at the Awards Ceremony and displayed on the Windsor-Essex County Health Unit’s website.</p>
                                
                                <TextArea
                                    name="winnersBooklet"
                                    style={{ width: "100%", height: "250px" }}
                                    onChange={e => this.fieldChange(e)}
                                    value={this.state.winnersBooklet}
                                    />

                                    <Notes 
                                        topicLevel={this.props.topicLevel}
                                        user={this.props.user}
                                        panel='booklet'
                                        />
                                
                            </div>

                            <input
                                 style={{ right:"50px" , width: "250px", position:"fixed", bottom:"30px" }}
                                type="submit"
                                className="bp3-button bp3-intent-primary bp3-large"
                                value="Save Application"></input>
                        </form>
                    </TopContainer>
                    </div>
                </div>
            
        );
    }
}

export default ApplicationForm;
