import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import TopContainer from '../hoc/TopContainer';
import { Dialog } from '@blueprintjs/core';

import { base } from '../hoc/Base';

import medal from '../assets/images/medal.png';
import platinum from '../assets/images/platinum.png';
import plus from '../assets/images/medal1plus.png';

import * as YEAR from '../constants/year'

const gordAwardLevelStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
}

const gordLevelButton = {
    maxWidth: "100%",
    flex: "1",
    margin: "5px",
    textAlign: "center"
}


class AwardBFLevel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            awardLevels: [
                { id: 1, level: 'Bronze Level', color: 'bronze', icon: medal },
                { id: 2, level: 'Silver Level', color: 'silver', icon: medal },
                { id: 3, level: 'Gold Level', color: 'gold', icon: medal },
                { id: 4, level: 'Platinum', color: 'platinum', icon: platinum },
                { id: 5, level: 'Platinum Plus', color: 'platinum', icon: plus }
            ],
            isOpen:false,
        }
    }

    deleteInput = (user) =>{
        base.remove(`awards/${user}/${YEAR.CURRENT_YEAR}/BikeFriendly/bikeFriendly`).then(()=> {
        })
        base.remove(`awards/${user}/${YEAR.CURRENT_YEAR}/BikeFriendly/awardLevel`).then(()=> {
        })
    }

     //toggle dialog box off
     toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });

    render() {
        //check if awardlevel is not set,  display all buttons
        if (this.props.awardLevel === undefined) {
            return (
                <div>
                    <h1>Bike Friendly</h1>
                    <h3 style={{ textAlign: "center" }}>Please select the award level that you are applying for.</h3>
                    <p style={{ textAlign: "center" }}> If you have already selected you award level, click on the highlighted box to access your application</p>

                    <TopContainer>
                        <div style={gordAwardLevelStyle}>
                        {this.state.awardLevels.map((level)=>{
                            return(
                                <Link to={`/bf-application-form/${this.props.user}`}
                                    key={level.id}
                                    style={gordLevelButton}
                                    className={level.color + " bp3-card bp3-elevation-0 bp3-interactive"}
                                    onClick={() => this.props.addBFAwardLevel(level.level)}>
                                    <img src={level.icon} alt={level.level} />
                                    <h3>{level.level}</h3>
                                </Link>
                            )
                        })}
                        </div>   
                    </TopContainer>
                </div>
            )
        }else{
            return (
                <div>

                     <Dialog
                    isOpen={this.state.isOpen}
                    onClose={this.toggleDialog}
                    title="Rest Application"
                >
                    <div className="bp3-dialog-body">
                        
                        <p> By resetting the Bike Friendly Application all form inputs will be deleted.  Do you wish to continue?</p>
                        <div >
                    <button onClick={() => this.deleteInput(this.props.user)} type="button" className="bp3-button bp3-intent-danger">
                        Yes reset me
                    </button>
                </div>
                    </div>
                    
                </Dialog>
                    <h1>Bike Friendly</h1>
                    <h3 style={{ textAlign: "center" }}>Please select the award level that you are applying for.</h3>
                    <p style={{ textAlign: "center" }}> If you have already selected you award level, click on the highlighted box to access your application</p>

                    <TopContainer>
                        <div style={gordAwardLevelStyle}>
                            {this.state.awardLevels.map((level) => {
                                return (
                                    this.props.awardLevel === level.level
                                        ?
                                        < Link to={`/bf-application-form/${this.props.user}`}
                                            key={level.id}
                                            style={gordLevelButton}
                                            className={level.color + " bp3-card bp3-elevation-0 bp3-interactive"}
                                            onClick={() => this.props.addBFAwardLevel(level.level)}>
                                            <img src={level.icon} alt={level.level} />
                                            <h3>{level.level}</h3>
                                        </Link>
                                        : <div style={gordLevelButton}
                                            key={level.id}
                                            className=" bp3-card bp3-elevation-0 disabledLink">
                                            <img src={level.icon} alt={level.level} />
                                            <h3>{level.level}</h3>
                                        </div>
                                )
                            })}

                        </div>
                    </TopContainer>
                    <div >
                    <button onClick={this.toggleDialog} text="Show dialog"  type="button" className="bp3-button bp3-intent-danger">
                        Reset Application
                    </button>
                </div>
                </div>
            )
        }
    }
}

export default AwardBFLevel;