import React, { Component } from 'react';
import { base } from '../hoc/Base';
import {Dialog, Card} from '@blueprintjs/core';
import MainReviewerNotes from '../components/Form/MainReviewerNotes';
import * as YEAR from '../constants/year';

import { Link } from 'react-router-dom';


class Reviewer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: [],
            value: 'chooseAnItem',
            isOpen: false,
            orgID: ''
        }
    }

    reviewStatus = (userID, event) =>{
        base.post(`awards/${userID}/reviewStatus`, {
            data: event.target.value,
        }).catch(err =>{
            alert(err)
        })
        base.fetch(`awards`, {
            context: this,
            asArray: true,
        }).then(data => {
            this.setState({
                formData: data,
            })
        })
    }

    //toggle dialog box off
    toggleDialog = (e) => this.setState({ 
                isOpen: !this.state.isOpen,
                orgID: e
            });
            
    checkProgress = (progress) =>{
        if (progress.hasOwnProperty("GordSmith")){
            let data = progress.GordSmith
            if (data.hasOwnProperty("progress")){
                return(data.progress)
            }
        }
    }
    
    checkBFProgress = (progress) =>{
        if(progress.hasOwnProperty("BikeFriendly")){
            let data = progress.BikeFriendly
            if (data.hasOwnProperty("progress")){
                return(data.progress)
            }
        }
    }
            
    componentDidMount(){
        //fetch awards data
        base.fetch(`awards`, {
            context: this,
            asArray: true,
        }).then(data => {
            this.setState({
                formData: data,
            })
        })
    }

    render() {
        return (
            <div>
                 <Dialog
                    style={{width:750}}
                    isOpen={this.state.isOpen}
                    onClose={this.toggleDialog}
                    title={'Reviewer Notes'}
                >
                    <div className="bp3-dialog-body">
                        <MainReviewerNotes 
                            orgID ={this.state.orgID}
                        />
                    </div>
                </Dialog>

                <h1>Review</h1>

                <Card>
                    <table className="bp3-table bp3-striped" style={{width: "100%"}}>
                        <thead>
                            <tr>
                                <th>Organization</th>
                                <th>Gord Smith</th>
                                <th>Bike Friendly</th>
                                <th>Review Status</th>
                            </tr>
                        </thead>
    
                        <tbody>
                            {this.state.formData.map((formD) => {
                                return (
                                    formD[YEAR.CURRENT_YEAR] ? 
                                        <tr key={formD.key}>

                                                <td><p>{formD.orgName.name}</p></td>
                                                <td>{formD[YEAR.CURRENT_YEAR].GordSmith ?
                                                    <p>
                                                        <Link to={`/review/topic1/${formD.key}`} >{formD[YEAR.CURRENT_YEAR].GordSmith.awardLevel}
                                                        </Link><br /> <strong>{this.checkProgress(formD[YEAR.CURRENT_YEAR])}</strong>
                                                    </p>
                                                    :
                                                    'No Submitted Application'}</td>
                                                <td>{formD[YEAR.CURRENT_YEAR].BikeFriendly ? 
                                                    <p>
                                                        <Link to={`/BFReview/${formD.key}`} >{formD[YEAR.CURRENT_YEAR].BikeFriendly.awardLevel} </Link><br /> <strong>{this.checkBFProgress(formD[YEAR.CURRENT_YEAR])}</strong>
                                                    </p>
                                                    : 
                                                    'No Submitted Application'}</td>
                                                <td><div className="bp3-select .modifier">
                                                        <select value={formD.reviewStatus} onChange={(e) => this.reviewStatus(formD.key, e)}>
                                                            <option value="chooseAnItem">Choose Status</option>
                                                            <option value="InReview">Reviewed by Reviewer 1</option>
                                                            <option value="FollowUp">Follow Up</option>
                                                            <option value="Completed">Completed</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                <button onClick={()=>this.toggleDialog(formD.key)}
                                    className="bp3-button bp3-intent-primary bp3-fill">Summary Reviewer Notes</button>
                                                </td>

                                        </tr>
                                    : null
                                )
                            })}
                        </tbody>
                    </table>
                </Card>
            </div>
        );
    }
}

export default Reviewer;
