import React, { Component } from 'react';
import { Dialog, Icon } from '@blueprintjs/core';

class Description extends Component {
    constructor(props) {
        super(props);
    
        this.state = ({
            isOpen: false
        })
    }

    //toggle dialog box off
    toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });

    render() {
    let dec = <p>Please select a Wellness Topic</p>;
    if(this.props.panelDes === "Awareness Raising"){
      if(this.props.wellnesstopic==="Mental-Health-Promotion"){
        dec = <div><p>Awareness Raising Activities are activities that raise awareness about important health issues by using effective communication strategies that reach all of your employees, such as sharing health information through posters, pamphlets or newsletters on bulletin boards, e-mail blasts, social media posts, information on paystubs or through posting on an internal workplace website.  Refer to our website for example activities for mental health promotion in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/mental-health-promotion-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Healthy-Eating"){
        dec = <div><p>Awareness Raising Activities are activities that raise awareness about important health issues by using effective communication strategies that reach all of your employees, such as sharing health information through posters, pamphlets or newsletters on bulletin boards, e-mail blasts, social media posts, information on paystubs or through posting on an internal workplace website.  Refer to our website for example activities for promoting healthy eating in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/promoting-healthy-eating-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Physical-Activity"){
        dec = <div><p>Awareness Raising Activities are activities that raise awareness about important health issues by using effective communication strategies that reach all of your employees, such as sharing health information through posters, pamphlets or newsletters on bulletin boards, e-mail blasts, social media posts, information on paystubs or through posting on an internal workplace website.  Refer to our website for example activities for promoting physical activity in the workplace.</p>
          <a href="https://www.wechu.org/workplace-health-programs/promoting-physical-activity-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
       </div>
      }else if(this.props.wellnesstopic==="Preventing-Injury"){
        dec = <div><p>Awareness Raising Activities are activities that raise awareness about important health issues by using effective communication strategies that reach all of your employees, such as sharing health information through posters, pamphlets or newsletters on bulletin boards, e-mail blasts, social media posts, information on paystubs or through posting on an internal workplace website.  Refer to our website for example activities for preventing injury in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/preventing-injury-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Infection-Control-and-Prevention"){
        dec = <div><p>Awareness Raising Activities are activities that raise awareness about important health issues by using effective communication strategies that reach all of your employees, such as sharing health information through posters, pamphlets or newsletters on bulletin boards, e-mail blasts, social media posts, information on paystubs or through posting on an internal workplace website.  Refer to our website for example activities for infection control and prevention in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/infection-control-and-prevention-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Substance-Use"){
        dec = <div><p>Awareness Raising Activities are activities that raise awareness about important health issues by using effective communication strategies that reach all of your employees, such as sharing health information through posters, pamphlets or newsletters on bulletin boards, e-mail blasts, social media posts, information on paystubs or through posting on an internal workplace website.  Refer to our website for example activities for substance use resources for the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/substance-use-resources-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Other"){
      dec = <div><p>Awareness Raising Activities are activities that raise awareness about important health issues by using effective communication strategies that reach all of your employees, such as sharing health information through posters, pamphlets or newsletters on bulletin boards, e-mail blasts, social media posts, information on paystubs or through posting on an internal workplace website.</p>  
        <a href="https://www.wechu.org/professionals/workplace-health-programs" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">Example activities for various wellness topics</a>
      </div>
    }
    }else if(this.props.panelDes === "Education or Skill Building"){
      if(this.props.wellnesstopic==="Mental-Health-Promotion"){
        dec = <div><p>Education and Skill Building Activities are activities that help employees develop the knowledge and skills necessary to support healthy living, such as through lunch and learn presentations, demonstrations, hands-on learning, webinars, health fairs, workplace challenges, or workplace contests. Refer to our website for example activities for mental health promotion in the workplace.</p><a href="https://www.wechu.org/workplace-health-programs/mental-health-promotion-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a></div>
      }else if(this.props.wellnesstopic==="Healthy-Eating"){
        dec = <div><p>Education and Skill Building Activities are activities that help employees develop the knowledge and skills necessary to support healthy living, such as through lunch and learn presentations, demonstrations, hands-on learning, webinars, health fairs, workplace challenges, or workplace contests. Refer to our website for example activities for promoting healthy eating in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/promoting-healthy-eating-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Physical-Activity"){
        dec = <div><p>Education and Skill Building Activities are activities that help employees develop the knowledge and skills necessary to support healthy living, such as through lunch and learn presentations, demonstrations, hands-on learning, webinars, health fairs, workplace challenges, or workplace contests.  Refer to our website for example activities for promoting physical activity in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/promoting-physical-activity-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Preventing-Injury"){
        dec = <div><p>Education and Skill Building Activities are activities that help employees develop the knowledge and skills necessary to support healthy living, such as through lunch and learn presentations, demonstrations, hands-on learning, webinars, health fairs, workplace challenges, or workplace contests.  Refer to our website for example activities for preventing injury in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/preventing-injury-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Infection-Control-and-Prevention"){
        dec = <div><p>Education and Skill Building Activities are activities that help employees develop the knowledge and skills necessary to support healthy living, such as through lunch and learn presentations, demonstrations, hands-on learning, webinars, health fairs, workplace challenges, or workplace contests.  Refer to our website for example activities for infection control and prevention in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/infection-control-and-prevention-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Substance-Use"){
        dec = <div><p>Education and Skill Building Activities are activities that help employees develop the knowledge and skills necessary to support healthy living, such as through lunch and learn presentations, demonstrations, hands-on learning, webinars, health fairs, workplace challenges, or workplace contests.  Refer to our website for example activities for substance use resources for the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/substance-use-resources-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Other"){
        dec = <div><p>Education and Skill Building Activities are activities that help employees develop the knowledge and skills necessary to support healthy living, such as through lunch and learn presentations, demonstrations, hands-on learning, webinars, health fairs, workplace challenges, or workplace contests.  </p>
        <a href="https://www.wechu.org/professionals/workplace-health-programs" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">Example activities for various wellness topics</a>
        </div>
      }
    }else if(this.props.panelDes === "Environmental Support"){
      if(this.props.wellnesstopic==="Mental-Health-Promotion"){
        dec = <div><p>Environmental Supports are things, or things that are done, to create a physical, social, and cultural workplace environment that encourage and support employees in making healthier choices. Supportive environments help make the healthier choice the easier choice in the workplace and beyond. Refer to our website for example environmental supports for mental health promotion in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/mental-health-promotion-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Healthy-Eating"){
        dec = <div><p>Environmental Supports are things, or things that are done, to create a physical, social, and cultural workplace environment that encourage and support employees in making healthier choices. Supportive environments help make the healthier choice the easier choice in the workplace and beyond. Refer to our website for example environmental supports for promoting healthy eating in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/promoting-healthy-eating-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Physical-Activity"){
        dec = <div><p>Environmental Supports are things, or things that are done, to create a physical, social, and cultural workplace environment that encourage and support employees in making healthier choices. Supportive environments help make the healthier choice the easier choice in the workplace and beyond. Refer to our website for example environmental supports for promoting physical activity in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/promoting-physical-activity-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Preventing-Injury"){
        dec = <div><p>Environmental Supports are things, or things that are done, to create a physical, social, and cultural workplace environment that encourage and support employees in making healthier choices. Supportive environments help make the healthier choice the easier choice in the workplace and beyond. Refer to our website for example environmental supports for preventing injury in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/preventing-injury-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Infection-Control-and-Prevention"){
        dec = <div><p>Environmental Supports are things, or things that are done, to create a physical, social, and cultural workplace environment that encourage and support employees in making healthier choices. Supportive environments help make the healthier choice the easier choice in the workplace and beyond.  Refer to our website for example environmental supports for infection control and prevention in the workplace.</p>
        <a href="https://www.wechu.org/workplace-health-programs/infection-control-and-prevention-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Substance-Use"){
        dec = <div><p>Environmental Supports are things, or things that are done, to create a physical, social, and cultural workplace environment that encourage and support employees in making healthier choices. Supportive environments help make the healthier choice the easier choice in the workplace and beyond.  Refer to our website for example environmental supports for substance use resources for the workplace (e.g. tobacco, alcohol, cannabis, and other drugs).</p>
        <a href="https://www.wechu.org/workplace-health-programs/substance-use-resources-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Other"){
        dec = <div><p>Environmental Supports are things, or things that are done, to create a physical, social, and cultural workplace environment that encourage and support employees in making healthier choices. Supportive environments help make the healthier choice the easier choice in the workplace and beyond.</p>
        <a href="https://www.wechu.org/professionals/workplace-health-programs" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">Example activities for various wellness topics</a>
        </div>
      }
    }else if(this.props.panelDes === "Supportive Policy Requirement"){
      if(this.props.wellnesstopic==="Mental-Health-Promotion"){
        dec = <div>  <p><strong>Supportive Policies</strong> are put in place to ensure that awareness raising, education and skill building, and environmental support components are in place now and into the future.  Refer to our website for example supportive policies for mental health promotion in the workplace.</p>
          <p>These three types of policies are acceptable for the Gord Smith Health Workplace Awards program criteria: </p>
          <ul>
            <li><strong>Workplace Wellness Policies:</strong> These are standalone policies specific to mental health (e.g., Psychological Safety in the Workplace Policy). They do not include Health and Safety Policies that are required by legislation.</li>
            <li><strong>Policy statements within other policies: </strong>These are not full policies on mental health promotion, but may be included as a statement in another broader policy (e.g., compressed work week policy statement in a general human resources policy).  This may include organizational policies or union contract language.</li>
            <li><strong>Position statements:</strong> These are official organizational positions that are dated and signed by an organizational leader.  This may include organizational or work sector standards or human resources guidelines.  Letter or memos from senior management or unofficial documents would not qualify.</li>
          </ul>
          <a href="https://www.wechu.org/workplace-health-programs/mental-health-promotion-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Healthy-Eating"){
        dec = <div><p><strong>Supportive Policies</strong> are put in place to ensure that awareness raising, education and skill building, and environmental support components are in place now and into the future.  Refer to our website for example supportive policies for promoting healthy eating in the workplace.</p>
          <p>These three types of policies are acceptable for the Gord Smith Health Workplace Awards program criteria: </p>
          <ul>
            <li><strong>Workplace Wellness Policies:</strong> These are standalone policies specific to healthy eating (e.g., Healthy Vending Machine). They do not include Health and Safety Policies that are required by legislation.</li>
            <li><strong>Policy statements within other policies: </strong>These are not full policies on healthy eating, but may be included as a statement in another broader policy (e.g., dietician coverage included in the list of health service providers covered by your benefit plan). This may include organizational policies or union contract language.</li>
            <li><strong>Position statements:</strong> These are official organizational positions that are dated and signed by an organizational leader.  This may include organizational or work sector standards or human resources guidelines.  Letter or memos from senior management or unofficial documents would not qualify.</li>
          </ul>
          <a href="https://www.wechu.org/workplace-health-programs/promoting-healthy-eating-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
       </div>
      }else if(this.props.wellnesstopic==="Physical-Activity"){
        dec = <div><p><strong>Supportive Policies</strong> are put in place to ensure that awareness raising, education and skill building, and environmental support components are in place now and into the future. Refer to our website for example supportive policies for promoting physical activity in the workplace.</p>
        <p>These three types of policies are acceptable for the Gord Smith Health Workplace Awards program criteria:</p> 
        <ul>
          <li><strong>Workplace Wellness Policies:</strong> These are standalone policies specific to physical activity (e.g., Walking Meetings Policy). They do not include Health and Safety Policies that are required by legislation.</li>
          <li><strong>Policy statements within other policies: </strong>These are not full policies on promoting physical activity, but may be included as a statement in another broader policy (e.g., fitness membership reimbursement in a general human resources policy).  This may include organizational policies or union contract language.</li>
          <li><strong>Position statements:</strong> These are official organizational positions that are dated and signed by an organizational leader.  This may include organizational or work sector standards or human resources guidelines.  Letter or memos from senior management or unofficial documents would not qualify.</li>
        </ul>
        <a href="https://www.wechu.org/workplace-health-programs/promoting-physical-activity-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Preventing-Injury"){
        dec = <div><p><strong>Supportive Policies</strong> are put in place to ensure that awareness raising, education and skill building, and environmental support components are in place now and into the future.  Refer to our website for example supportive policies for preventing injury in the workplace.</p>
        <p>These three types of policies are acceptable for the Gord Smith Health Workplace Awards program criteria:</p> 
        <ul>
          <li><strong>Workplace Wellness Policies:</strong> These are standalone policies specific to preventing injury (e.g., Distracted Driving Policy). They do not include Health and Safety Policies that are required by legislation.</li>
          <li><strong>Policy statements within other policies: </strong>These are not full policies on a preventing injury, but may be included as a statement in another broader policy (e.g., an ergonomics assessment statement in a general human resources policy).  This may include organizational policies or union contract language.</li>
          <li><strong>Position statements:</strong> These are official organizational positions that are dated and signed by an organizational leader.  This may include organizational or work sector standards or human resources guidelines.  Letter or memos from senior management or unofficial documents would not qualify.</li>
        </ul>
        <a href="https://www.wechu.org/workplace-health-programs/preventing-injury-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Infection-Control-and-Prevention"){
        dec = <div><p><strong>Supportive Policies</strong> are put in place to ensure that awareness raising, education and skill building, and environmental support components are in place now and into the future. Refer to our website for example supportive policies for infection control and prevention in the workplace.</p>
        <p>These three types of policies are acceptable for the Gord Smith Health Workplace Awards program criteria:</p> 
        <ul>
          <li><strong>Workplace Wellness Policies:</strong> These are standalone policies specific to infection control and prevention (e.g., Influenza Immunization Policy). They do not include Health and Safety Policies that are required by legislation.</li>
          <li><strong>Policy statements within other policies: </strong>These are not full policies on infection control and prevention, but may be included as a statement in another broader policy (e.g., immunization statements to determine which immunizations are recommended as a condition of employment for new employees in a general human resources policy).  This may include organizational policies or union contract language.</li>
          <li><strong>Position statements:</strong> These are official organizational positions that are dated and signed by an organizational leader.  This may include organizational or work sector standards or human resources guidelines (e.g., assigning an individual or team to identify and respond to infection control concerns).  Letter or memos from senior management or unofficial documents would not qualify.</li>
        </ul>
        <a href="https://www.wechu.org/workplace-health-programs/infection-control-and-prevention-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Substance-Use"){
        dec = <div><p><strong>Supportive Policies</strong> are put in place to ensure that awareness raising, education and skill building, and environmental support components are in place now and into the future. Refer to our website for example supportive policies for substance use (e.g., tobacco, alcohol, cannabis, and other drugs) for the workplace.</p>
        <p>These three types of policies are acceptable for the Gord Smith Health Workplace Awards program criteria:</p> 
        <ul>
          <li><strong>Workplace Wellness Policies:</strong> These are standalone policies specific to substance use (e.g., Substance use Policy). They do not include Health and Safety Policies that are required by legislation.</li>
          <li><strong>Policy statements within other policies:</strong> These are not full policies on substance use, but may be included as a statement in another broader policy (e.g., Employee Assistance Program in a general human resources policy).  This may include organizational policies or union contract language.</li>
          <li><strong>Position statements:</strong> These are official organizational positions that are dated and signed by an organizational leader.  This may include organizational or work sector standards or human resources guidelines (e.g., Alcohol-Free Events).  Letter or memos from senior management or unofficial documents would not qualify.</li>
        </ul>
        <a href="https://www.wechu.org/workplace-health-programs/substance-use-resources-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }else if(this.props.wellnesstopic==="Other"){
        dec = <div><p><strong>Supportive Policies</strong> are put in place to ensure that awareness raising, education and skill building, and environmental support components are in place now and into the future. Refer to our website for example supportive policies for various workplace wellness topics.</p>
        <p>These three types of policies are acceptable for the Gord Smith Health Workplace Awards program criteria: </p> 
        <ul>
            <li><strong>Workplace Wellness Policies:</strong> These are standalone policies specific to a wellness topic (e.g., Healthy Vending Machine Policy specific to healthy eating). They do not include Health and Safety Policies that are required by legislation.</li>
            <li><strong>Policy statements within other policies: </strong>These are not full policies on , but may be included as a statement in another broader policy (e.g., fitness membership reimbursement in a general human resources policy).  This may include organizational policies or union contract language.</li>
            <li><strong>Position statements:</strong> These are official organizational positions that are dated and signed by an organizational leader.  This may include organizational or work sector standards or human resources guidelines (e.g., alcohol-free events).  Letter or memos from senior management or unofficial documents would not qualify.</li>
        </ul>
        <a href="https://www.wechu.org/workplace-health-programs/substance-use-resources-workplace" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
        </div>
      }
    }else if(this.props.panelDes === "Evaluation"){
      dec = <div><p>A workplace wellness program evaluation identifies how effective your program was at improving the wellbeing of your employees. It can also tell you how effective you were in getting the word out to your employees, how interested your employees were in the activities you offered, and help you plan for future workplace wellness initiatives. Refer to our website for example evaluation resources. </p>
      <a href="https://www.wechu.org/workplace-health-programs/workplace-wellness-planning-and-evaluation-resources" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
      </div>
    }else if(this.props.panelDes === "Comprehensive Workplace Wellness Policy"){
      dec = <div><p>A comprehensive workplace wellness policy  specifies your employer’s commitment to organizational practices that promote and support the health and well-being of employees and their families; outlines senior management and employee roles and responsibilities regarding wellness; and defines how the wellness program is communicated and offered to employees and their families. Select the second drop down question. </p>
      <a href="https://www.wechu.org/workplace-health-programs/gord-smith-healthy-workplace-awards-criteria" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a></div>
    }else if(this.props.panelDes === "Organizational Social Responsibility"){
      dec = <p>Organizational social responsibility activities are activities that demonstrate your organization’s contribution to improving the health and well-being of the community in addition to their own employees.  It recognizes that supporting our community, even in a small way, contributes to individual wellbeing. It also helps promote a positive public image while raising awareness about important health and wellness issues.  Examples include sponsoring local children’s sports teams, participating in fundraising for health research or health issues (e.g., dress down days, BBQs, or golf tournaments), participating in community physical activity challenges (e.g., walks, runs, bikes, or obstacle courses), neighborhood clean-up, donation drives (e.g. blood, clothing, or toys), or volunteering hours in the community (e.g., food bank or community build projects). </p>
    }
    else if(this.props.panelDes === "Supportive Policy"){
      dec = <div><p>Supportive policies are policies that guide and support employees in making healthy decisions in relation to a specific health topic. Examples of supportive policies include healthy meetings policies, job sharing policies, and professional development policies. </p>
      
      <a href="https://www.wechu.org/professionals/workplace-health-programs" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
      </div>
    }else if(this.props.panelDes === "Living Wage"){
      dec = <div><p>A living wage is different from the minimum wage. The minimum wage rate is pre-set by the government and is the lowest rate an employer can legally pay for work performed. A living wage is calculated based on the real cost of goods and services in Windsor and Essex County and is the minimum amount a person must earn to afford to live and participate in our community. A living wage takes into consideration basic level of economic security and quality of life for most two-parent families.  It provides the means to raise children and take part in social and community activities. This means that the rate varies from community to community and may change year-to-year.   Diamond and Platinum level award recipients must have received or re-certified as a Living Wage Certified Employer with the past two years, between July 20, 2017 and August 30, 2019. Applications are typically reviewed and a decision is provided within 10 days of applying. </p>

      <a href="https://www.wechu.org/living-wage" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">Find out more or apply</a></div>
    }else if(this.props.panelDes === "statement"){
      dec = <p>A statement of an employer’s commitment to employee wellness displays a step toward the development of a workplace wellness program. For example:<br/>
      <br/>
      (insert company name) is committed to supporting the health and well-being of its employees.  (insert company name) will achieve this by planning activities that promote an awareness of a health topic, or educate employees about health living, or foster a supportive environment that makes the healthy choice the easier choice for employees.
       </p>
    }else if(this.props.panelDes === "Policy"){
      dec = <div><p>Bike friendly policies demonstrate workplace support for using the bicycle as a form of active transportation. Examples of bike friendly policies include work travel policies and maintenance or security of bicycles policies. </p>
      <a href="https://wechu.org/workplace-health-programs/bike-friendly-workplace-awards" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
      </div>
    }else if(this.props.panelDes === "Promotion"){
      dec = <div><p>Bike friendly promotion encourages cycling as part of its organizational culture. Examples of promotion include brochures, posters, or contests.  </p>
      <a href="https://wechu.org/workplace-health-programs/bike-friendly-workplace-awards" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
      </div>
    }else if(this.props.panelDes === "Education"){
      dec = <div><p>Bike friendly education provides information about cycling to employees. Examples include bicycle safety basics, proper fitting of a bicycle helmet, or the health benefits of cycling.  </p>
      <a href="https://wechu.org/workplace-health-programs/bike-friendly-workplace-awards" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
      </div>
    }else if(this.props.panelDes === "Environmental Supports BF"){
      dec = <div><p>Bike friendly environmental supports provide facilities to support employees in riding their bicycles to work. Examples include maps of cycling routes to the workplace, safe and secure bicycle parking, or employee change rooms. </p>
      <a href="https://wechu.org/workplace-health-programs/bike-friendly-workplace-awards" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
      </div>
    }else if(this.props.panelDes === "Incentives"){
      dec = <div><p>Bike friendly incentives encourage and support employees in cycling to work. Examples include discounts at cycling stores, bicycle safety kits, or an annual stipend toward bicycle maintenance. </p>
      <a href="https://wechu.org/workplace-health-programs/bike-friendly-workplace-awards" target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-primary bp3-fill" type="button">View Examples</a>
      </div>
    }else if(this.props.panelDes === "Pro Bicycle Policy Statement"){
      dec = <div><p>A pro bicycle policy statement outlines organizational commitment to all categories (promotion, education, environmental supports, and incentives) required to support a bike friendly workplace.  This is only required for the Platinum Plus level.  An example could include this statement within the Bike Friendly Workplace Policy:  Our workplace provides education, environmental supports, and incentives to promote employees to use their bicycle as a form of active transportation.   </p>
      </div>
    }
  

    return (
      <div style={{ padding: "0px", float:"right"}} className="description bp3-button bp3-intent-primary ">
         <Dialog
            isOpen={this.state.isOpen}
            onClose={this.toggleDialog}
        >
            <div className="bp3-dialog-body">
                <h3>{this.props.panelDes==="Environmental Supports BF" ? "Environmental Supports" : this.props.panelDes}</h3>
                {dec}
            </div>
            
        </Dialog>

        <span style={{ paddingLeft: "8px", paddingRight: "8px",display: "block" }} onClick={this.toggleDialog}><Icon icon="info-sign" /></span>
      </div>
    );
  }
}

export default Description;
