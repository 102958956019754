import React, { Component } from 'react';

import {Icon, Intent} from '@blueprintjs/core';


class Submit extends Component {
  render() {
    return (
      <div>
        <h1>Submitted</h1>
        <div className="bp3-card bp3-elevation-3">
            <p style={{fontSize:"30px", textAlign:"center"}}>Congratulations you have successfully submitted your application<br />
            <br />
            <Icon icon="endorsed" iconSize={100} intent={Intent.PRIMARY} />
            </p>
            <br />
            <hr />
      
            <p style={{fontSize:"20px", textAlign:"center", fontWight:"bold"}}> Please keep in mind you can still make changes to your application up until<br/> August 30, 2019.</p>
        </div>
      </div>
    );
  }
}

export default Submit;
