import React, { Component } from 'react';

import SideMenuAdmin from '../components/Form/SideMenuAdmin';
import FileDownload from '../components/Form/FormItems/FileDownload';
import ReviewerNotes from '../components/Form/ReviewerNotes';

import * as YEAR from '../constants/year';

import { base } from '../hoc/Base';

class AdditionalDocumentsReview extends Component {
    constructor(props) {
        super(props);
        this.state = ({ 
            awardType: '',
            awardLevel: '',
            evaluation:'',
            evaluationCon: '',
            comprehensive:'',
            orgSocialRespActivity1:'',
            orgSocialRespActivity2:'',
            spportivePolicy:'',
            radioValue: '',
            livingWage: '',
            winnersBooklet: '',
            wordCount: '',
            org:''
        })
    }

    componentDidMount() {
        const { match: {params} } = this.props;
        this.setState({
            org: params
        })

        base.fetch(`awards/${params.uid}/${YEAR.CURRENT_YEAR}/GordSmith`, {
            context: this,
        }).then(data => {
            this.setState({
                formData: data
            })
            this.setState({
                awardType: this.state.formData.awardType,
                awardLevel: this.state.formData.awardLevel
            })
            if (this.props.topicLevel === 'additionalDocument') {
                if ('additionalDocument' in data){
                    this.setState({
                        evaluation: this.state.formData.additionalDocument[0].evaluation,
                        evaluationCon: this.state.formData.additionalDocument[1].evaluationCon,
                        evaluation2: this.state.formData.additionalDocument[2].evaluation2,
                        evaluationCon2:this.state.formData.additionalDocument[3].evaluationCon2,
                        comprehensivePolicy:this.state.formData.additionalDocument[4].comprehensivePolicy,
                        comprehensive: this.state.formData.additionalDocument[5].comprehensive,
                        orgSocialRespActivity1: this.state.formData.additionalDocument[6].orgSocialRespActivity1,
                        orgSocialRespActivity2: this.state.formData.additionalDocument[7].orgSocialRespActivity2,
                        spportivePolicy:this.state.formData.additionalDocument[8].spportivePolicy,
                        radioValue: this.state.formData.additionalDocument[9].radioValue,
                        livingWage: this.state.formData.additionalDocument[10].livingWage,
                        winnersBooklet: this.state.formData.additionalDocument[11].winnersBooklet,
                    })
                }
            } else {
                    alert('object does not exist')
            }
        })
    }

    render() {
        return (
            <div className="form">
                <div className="side-menu">
                    <SideMenuAdmin
                        awardType={this.state.awardType}
                        awardLevel={this.state.awardLevel}
                        org={this.state.org.uid} />
                </div>

                <div className="application-form">
                        {/* ----------------------Evaluation---------------------- */}
                            {this.state.awardLevel === "Bronze Level 2" || 
                             this.state.awardLevel === "Silver Level 1" ||  
                             this.state.awardLevel === "Silver Level 2" ||  
                             this.state.awardLevel === "Gold Level 1" || 
                             this.state.awardLevel === "Gold Level 2" || 
                             this.state.awardLevel === "Platinum" ||
                             this.state.awardLevel === "Diamond" ?
                                <div className="bp3-card silver">
                                    <h5>Evaluation</h5>
                                    
                                    <p>Provide evidence of a type of evaluation piece you completed for {this.state.awardLevel === "Gold Level 1" || this.state.awardLevel === "Gold Level 2" || this.state.awardLevel === "Platinum" ||this.state.awardLevel === "Diamond" ? 'TWO' : 'One'} of the activities you listed under awareness raising, education and skill building, or environmental support in the previous sections.</p>
                                    
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of activity you're providing evaluation for</label>
                                        <div className="bp3-card">
                                            <p>{this.state.evaluation}</p>
                                        </div>

                                        <label className="bp3-label">How evaluation was conducted</label>
                                        <div className="bp3-card">
                                            <p>{this.state.evaluationCon}</p>
                                        </div>

                                        <div>
                                            <label className="bp3-label">Attach Evidence of Evaluation</label>

                                           <FileDownload
                                                awardType='GordSmith' 
                                                uploadPane="evaluation"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid}
                                                 />

                                        </div>
                                        <ReviewerNotes 
                                            panel='evaluation'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                    </div>

                                 {this.state.awardLevel === "Gold Level 1" ||  this.state.awardLevel === "Gold Level 2" || this.state.awardLevel === "Platinum" || this.state.awardLevel === "Diamond"   ?
                                
                                <div className="bp3-card bp3-elevation-2">
                                     <label className="bp3-label">Name of activity you're providing evaluation for</label>
                                     <div className="bp3-card">
                                         <p>{this.state.evaluation2}</p>
                                     </div>

                                     <label className="bp3-label">How evaluation was conducted</label>
                                     <div className="bp3-card">
                                         <p>{this.state.evaluationCon2}</p>
                                     </div>

                                     <div>
                                         <label className="bp3-label">Attach Evidence of Evaluation</label>

                                         <FileDownload
                                                awardType='GordSmith' 
                                                uploadPane="evaluation2"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />

                                     </div>
                                     <ReviewerNotes 
                                            panel='evaluation2'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                 </div> : null
                                }
                                </div> : null
                            }

                           

                            {/* ----------------------Comprehensive Workplace Wellness Policy---------------------- */}
                            {this.state.awardLevel === "Silver Level 1" || this.state.awardLevel === "Silver Level 2" || this.state.awardLevel === "Gold Level 1" || this.state.awardLevel === "Gold Level 2" || this.state.awardLevel === "Platinum" || this.state.awardLevel === "Diamond" ?
                                <div className="bp3-card silver">
                                    <h5>Comprehensive Workplace Wellness Policy</h5>
                                    <p>Please provide a copy of your Comprehensive Workplace Wellness Policy</p>
                                    <div className="bp3-card bp3-elevation-2">
                                        <div>
                                            <label className="bp3-label">Attach Policy</label>

                                            <FileDownload
                                                awardType='GordSmith' 
                                                uploadPane="comprehensive"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />
                                            
                                        </div>
                                        <ReviewerNotes 
                                            panel='comprehensive'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                    </div>
                                </div> : null}

                            {/* ----------------------Organizational Social Responsibility---------------------- */}
                            {this.state.awardLevel === "Gold Level 2" || this.state.awardLevel === "Platinum" || this.state.awardLevel === "Diamond" ?
                                <div className="bp3-card silver">
                                    <h5>Organizational Social Responsibility</h5>
                                    {this.state.awardLevel === "Platinum" || this.state.awardLevel === "Diamond" ?
                                    <p>Provide TWO examples of an activity or program where your workplace is active in the community to support efforts to improve the health and well-being of the community.</p> : 
                                    <p>Provide ONE examples of an activity or program where your workplace is active in the community to support efforts to improve the health and well-being of the community.</p>
                        }
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of Activity</label>
                                        <div className="bp3-card">
                                            <p>{this.state.orgSocialRespActivity1}</p>
                                        </div>
                                        <div>
                                            <label className="bp3-label">Attach evidence for this activity</label>

                                            <FileDownload
                                                awardType='GordSmith' 
                                                uploadPane="orgSocialRespActivity1"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />
                                            
                                        </div>
                                        <ReviewerNotes 
                                            panel='orgSocialRespActivity1'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                    </div>
                                    {this.state.awardLevel === "Platinum" || this.state.awardLevel === "Diamond" ?
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of Activity</label>
                                        <div className="bp3-card">
                                            <p>{this.state.orgSocialRespActivity2}</p>
                                        </div>
                                        <div>
                                            <label className="bp3-label">Attach evidence for activity</label>

                                            <FileDownload
                                                awardType='GordSmith' 
                                                uploadPane="orgSocialRespActivity2"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />
                                            
                                        </div>
                                        <ReviewerNotes 
                                            panel='orgSocialRespActivity2'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                    </div> : null
                                    }
                                </div> : null}

                            {/* ----------------------Supportive Policy Requirement---------------------- */}
                            {/* {this.state.awardLevel === "Bronze Level 1" || this.state.awardLevel === "Bronze Level 2" || this.state.awardLevel === "Silver Level 1" ?
                                <div className="bp3-card">
                                    <h5>Supportive Policy Requirement</h5>
                                    {this.state.awardLevel === "Silver Level 1" ? <p>Provide ONE supportive policies specific to the wellness topic chosen</p>
                                        : null
                                    }
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of policy specific to the wellness topic chosen</label>
                                        <div className="bp3-card">
                                            <p>{this.state.spportivePolicy}</p>
                                        </div>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach Policy</label>

                                            <FileDownload
                                                awardType='GordSmith' 
                                                uploadPane="supportivePolicyReq"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />
                                            
                                        </div>
                                        <ReviewerNotes 
                                            panel='supportivePolicyReq'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                    </div>
                                </div> : null} */}

                            {/* ----------------------Living Wage---------------------- */}
                            {this.state.awardLevel === "Platinum" || this.state.awardLevel === "Diamond"  ?
                                <div className="bp3-card silver">
                                    <h5>Living Wage</h5>
                                        
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">My workplace is a Windsor and Essex County Living Wage Certified Employer </label>
                                        <div className="bp3-card">
                                        <p>{this.state.livingWage}</p>
                                        </div>

                                        <ReviewerNotes 
                                            panel='livingwage'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                    </div>

                                </div> : null}


                            {/* ----------------------Diamond Eligibility---------------------- */}
                            {this.state.awardLevel === "Diamond" ?
                                <div className="bp3-card silver">
                                    <h5>Diamond Eligibility</h5>
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">My workplace is eligible for this award because</label>
                                        <div className="bp3-card">
                                            <p>{this.state.radioValue}</p>
                                        </div>
                                        <ReviewerNotes 
                                            panel='diamonElig'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                    </div>
                                </div> : null}

                            
                            {/* ----------------------Winners's Booklet---------------------- */}        
                            {this.state.awardLevel === "Bronze Level 1" ||
                             this.state.awardLevel === "Bronze Level 2" || 
                             this.state.awardLevel === "Silver Level 1" ||  
                             this.state.awardLevel === "Silver Level 2" ||  
                             this.state.awardLevel === "Gold Level 1" || 
                             this.state.awardLevel === "Gold Level 2" || 
                             this.state.awardLevel === "Platinum" ||
                             this.state.awardLevel === "Diamond" ?
                            <div className="bp3-card silver">
                                <h5>Winners's Booklet</h5>
                                <p>Please provide a brief write-up that highlights one of your wellness activities throughout the year (200-word maximum)</p>
                                <div className="bp3-card bp3-elevation-2">
                                <p>{this.state.winnersBooklet}</p>
                                </div>

                                <ReviewerNotes 
                                    panel='booklet'
                                    topicLevel={this.props.topicLevel}
                                    org={this.state.org.uid}
                                    />
                            </div>:null}
                </div>
            </div>
        );
    }
}

export default AdditionalDocumentsReview;
