import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

const sideMenuStyles = {
    justifyContent: 'space-between',
    padding: '10px',
    background: "#fec00f"
}

const sideMenuListStyle = {
    fontSize:"20px",
    color:"#00596f",
    background:"#FFF",
    width: "100%",
    display:"block",
    padding:"10px",
    marginTop: "10px",
    borderRadius: "18px"
}

class SideMenu extends Component {

    render() {
        let sideMenuLinks = <p>Somting went wrong</p>

        if(this.props.awardType === "Gord Smith"){
            if (this.props.awardLevel === "Rising Star"){
                sideMenuLinks = 
                        <ul>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/application-form/topic1/${this.props.user}`}>Topic 1</NavLink></li>
                        </ul>
            }else if (this.props.awardLevel === "Bronze Level"){
                sideMenuLinks = 
                        <ul>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/application-form/topic1/${this.props.user}`}>Topic 1</NavLink></li>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/additional-documents/${this.props.user}`}>Additional Documents</NavLink></li>
                        </ul>
            }else if(this.props.awardLevel === "Silver Level"){
                sideMenuLinks = 
                        <ul>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/application-form/topic1/${this.props.user}`}>Topic 1</NavLink></li>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/application-form/topic2/${this.props.user}`}>Topic 2</NavLink></li>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/additional-documents/${this.props.user}`}>Additional Documents</NavLink></li>
                        </ul>
            }else{
                sideMenuLinks = 
                        <ul>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/application-form/topic1/${this.props.user}`}>Topic 1</NavLink></li>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/application-form/topic2/${this.props.user}`}>Topic 2</NavLink></li>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/application-form/topic3/${this.props.user}`}>Topic 3</NavLink></li>
                            <li><NavLink activeClassName='is-active' style={sideMenuListStyle} to={`/additional-documents/${this.props.user}`}>Additional Documents</NavLink></li>
                        </ul>
            }
        }else{
            sideMenuLinks = ''
        }

        return (
            <div className="bp3-card"  style={sideMenuStyles}>
                <div className="award-level-title">
                    <h2 style={{color: "#333f48 ", textAlign: "center"}} >
                        {this.props.awardLevel}
                    </h2>
                </div>

                {sideMenuLinks}
            </div>
        );
    }
}

export default SideMenu;
