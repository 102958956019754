import React, { Component } from 'react';

import { firebase } from '../../../hoc/Base';

const buttonStyle = {
    marginRight: '10px',
}

class FileDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileURL: ''
        }
    }

    componentDidMount() {
        let fileName = this.props.file;  
        const storage = firebase.storage();
        let pathReference = storage.ref(`${this.props.user}/${fileName}`);

        pathReference.getDownloadURL()
        .then(url =>{
            this.setState({
                fileURL: url
            })
        })
    }
    
    render() {
        return (
            <span >
            {this.props.year ==='2018' ?
                 <a style={buttonStyle} target="_blank" rel="noopener noreferrer" className="bp3-button bp3-intent-success bp3-icon-download bp3-align-right" href={this.state.fileURL}>Download</a>
                 : null
            }   
            </span >  
        )
    }

};

export default FileDownload;