import React, { Component } from 'react';

import { TextArea, Intent } from '@blueprintjs/core';
import { base } from  '../../hoc/Base';
import { AppToaster } from './Toaster';

import * as YEAR from '../../constants/year';


class MainReviewerNotes extends Component {
    constructor(props) {
        super(props);
        this.state={
            notes:''
        }
    }

    fieldChange(e) {
        this.setState({
            notes: e.target.value
        });
    }

    handleForm =(e) =>{
        e.preventDefault()
        //push notes to database
        base.update(`notes/${this.props.orgID}/reviewNotes/${YEAR.CURRENT_YEAR}/`, {
            data: {notes: this.state.notes}
          }).then(() => {
            AppToaster.show({ message:"Your note has been SAVED",
            intent: Intent.SUCCESS})
          }).catch(err => {
            //handle error
          });
    }
    
    componentWillMount(){
        base.fetch(`notes/${this.props.orgID}/reviewNotes/${YEAR.CURRENT_YEAR}/`, {
            context: this,
            asArray: false
        }).then(data => {
            if(data){
                this.setState({
                    notes: data.notes
                })
                
            }else{
                this.setState({
                    notes:''
            })
        }
        }).catch(error => {
            alert(error)
        })
    }


    render() {
        return (
            <form onSubmit={(event) => this.handleForm(event)}>
                    <div className="bp3-card bp3-elevation-2" style={{background: "#00596f"}}>
                    <div>
                        <TextArea
                            name={this.props.panel}
                            style={{ width: "100%", height: "250px" }}
                            onChange={e => this.fieldChange(e)}
                            placeholder="Enter notes"
                            value={this.state.notes}
                        />
                    </div>
                    <input
                    style={{ width: "100%" }}
                    type="submit"
                    className="bp3-button bp3-intent-primary bp3-large"
                    value="Save"></input>
                </div>
            </form>
        )
    }
};

export default MainReviewerNotes;