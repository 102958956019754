import Rebase from 're-base';
import * as firebase from 'firebase/app';
import '@firebase/database';
import "@firebase/auth";
import "@firebase/storage";

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
};

const app = firebase.initializeApp(config)
const firebasedb = firebase.database(app)
const base = Rebase.createClass(firebasedb)

export { base, app, firebasedb, firebase }