import React, { Component } from 'react';

import SideMenuAdmin from '../components/Form/SideMenuAdmin';
import { Dialog } from '@blueprintjs/core';

import TopContainer from '../hoc/TopContainer';
import FileDownload from '../components/Form/FormItems/FileDownload';
import ReviewerNotes from '../components/Form/ReviewerNotes';
import { base } from '../hoc/Base';

import * as YEAR from '../constants/year';

const header3Style = {
    marginTop: "18px"
}

class ReviewForm extends Component {
    constructor(props) {
        super(props);

        this.state = ({
            awardType: '',
            awardLevel: '',
            topic: '',
            awareness1: '',
            awareness1Dec: '',
            awareness2: '',
            awareness2Dec: '',
            education1: '',
            education1Dec: '',
            education2: '',
            education2Dec: '',
            environmental1: '',
            environmental1Dec: '',
            environmental2: '',
            environmental2Dec: '',
            policy1: '',
            policy2: '',
            formData: [],
            topic1Data:[],
            topic2Data:[],
            topic3Data:[],
            isOpen: false,
            org:''
        })
    }

    //toggle dialog box off
    toggleDialog = () => this.setState({ isOpen: !this.state.isOpen });

    componentDidMount() {
        const { match: {params} } = this.props;
        this.setState({
            org: params
        })

        base.fetch(`awards/${params.uid}/${YEAR.CURRENT_YEAR}/GordSmith`, {
            context: this,
        }).then(data => {
            console.log(data)
            this.setState({
                formData: data
            })
            this.setState({
                awardType: this.state.formData.awardType,
                awardLevel: this.state.formData.awardLevel
            })
            if (this.props.topicLevel === 'topic1') {
                if ('topic1' in data){
                    this.setState({
                        topic: this.state.formData.topic1[0].topic,
                        awareness1: this.state.formData.topic1[1].awareness1,
                        awareness1Dec: this.state.formData.topic1[2].awareness1Dec,
                        awareness2: this.state.formData.topic1[3].awareness2,
                        awareness2Dec: this.state.formData.topic1[4].awareness2Dec,
                        education1: this.state.formData.topic1[5].education1,
                        education1Dec: this.state.formData.topic1[6].education1Dec,
                        education2:this.state.formData.topic1[7].education2,
                        education2Dec:this.state.formData.topic1[8].education2Dec,
                        environmental1: this.state.formData.topic1[9].environmental1,
                        environmental1Dec: this.state.formData.topic1[10].environmental1Dec,
                        environmental2: this.state.formData.topic1[11].environmental2,
                        environmental2Dec: this.state.formData.topic1[12].environmental2Dec,
                        policy1: this.state.formData.topic1[13].policy1,
                        policy2: this.state.formData.topic1[14].policy2,
                        othertopic: this.state.formData.topic1[15].othertopic,
                        winnersBooklet: this.state.formData.topic1[16].winnersBooklet,
                })}
            }else if (this.props.topicLevel === 'topic2'){
                if ('topic2' in data){
                    this.setState({
                        topic: this.state.formData.topic2[0].topic,
                        awareness1: this.state.formData.topic2[1].awareness1,
                        awareness1Dec: this.state.formData.topic2[2].awareness1Dec,
                        awareness2: this.state.formData.topic2[3].awareness2,
                        awareness2Dec: this.state.formData.topic2[4].awareness2Dec,
                        education1: this.state.formData.topic2[5].education1,
                        education1Dec: this.state.formData.topic2[6].education1Dec,
                        education2:this.state.formData.topic2[7].education2,
                        education2Dec:this.state.formData.topic2[8].education2Dec,
                        environmental1: this.state.formData.topic2[9].environmental1,
                        environmental1Dec: this.state.formData.topic2[10].environmental1Dec,
                        environmental2: this.state.formData.topic2[11].environmental2,
                        environmental2Dec: this.state.formData.topic2[12].environmental2Dec,
                        policy1: this.state.formData.topic2[13].policy1,
                        policy2: this.state.formData.topic2[14].policy2,
                        othertopic: this.state.formData.topic2[15].othertopic,
                })}
            }else if (this.props.topicLevel === 'topic3'){
                if ('topic3' in data){
                    this.setState({
                        topic: this.state.formData.topic3[0].topic,
                        awareness1: this.state.formData.topic3[1].awareness1,
                        awareness1Dec: this.state.formData.topic3[2].awareness1Dec,
                        awareness2: this.state.formData.topic3[3].awareness2,
                        awareness2Dec: this.state.formData.topic3[4].awareness2Dec,
                        education1: this.state.formData.topic3[5].education1,
                        education1Dec: this.state.formData.topic3[6].education1Dec,
                        education2:this.state.formData.topic3[7].education2,
                        education2Dec:this.state.formData.topic3[8].education2Dec,
                        environmental1: this.state.formData.topic3[9].environmental1,
                        environmental1Dec: this.state.formData.topic3[10].environmental1Dec,
                        environmental2: this.state.formData.topic3[11].environmental2,
                        environmental2Dec: this.state.formData.topic3[12].environmental2Dec,
                        policy1: this.state.formData.topic3[13].policy1,
                        policy2: this.state.formData.topic3[14].policy2,
                        othertopic: this.state.formData.topic3[15].othertopic,
                })}
            } else {
                    alert('object does not exist')
            }
            
        })
        
    }

    render() {
        return (
            <div className="form">
                <Dialog
                    isOpen={this.state.isOpen}
                    onClose={this.toggleDialog}
                    title={this.props.topicLevel}
                >
                    <div className="bp3-dialog-body">
                        <h3>Application Saved</h3>
                        <p> Remember you can always come back and edit your information.</p>
                    </div>
                    
                </Dialog>

                <div className="side-menu">
                    <SideMenuAdmin
                        awardType={this.state.awardType}
                        awardLevel={this.state.awardLevel}
                        org={this.state.org.uid}
                         />
                </div>

                <div className="application-form">
                    <TopContainer>
                        {/* <div>
                            <h2 style={{ fontSize: "36px", color: "#00596f", marginBottom: "18px" }}>Wellness {this.props.topicLevel}</h2>
                        </div> */}

                        
                            {/* ----------------------wellness Topoic---------------------- */}
                            <div className="bp3-card silver">

                                <label className="bp3-label">Name of wellness topic that you have focused on throughout the year</label>
                                <h2>{this.state.topic}</h2>
                                <h3>{this.state.topic === 'Other' ?  this.state.othertopic : null}</h3>
                            </div>

                            <h3 style={header3Style}>Activities for the following health promotion strategies:</h3>

                            {/* ----------------------Awarness Rising---------------------- */}
                            <div className="bp3-card silver">
                                <h5>Awareness Raising</h5>
                                {this.state.awardLevel=== 'Rising Star' ||
                                this.state.awardLevel === 'Bronze Level 1' || 
                                this.state.awardLevel === 'Bronze Level 2' || 
                                this.state.awardLevel === 'Silver Level 1' || 
                                this.state.awardLevel === 'Silver Level 2' ||
                                this.state.awardLevel === 'Gold Level 1' ||
                                this.state.awardLevel === 'Gold Level 2' ||
                                 this.state.awardLevel === 'Platinum' || 
                                 this.state.awardLevel === 'Diamond' ?
                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Name of activity</label>
                                    <div className="bp3-card">
                                        <p>{this.state.awareness1} </p>
                                    </div>

                                    <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                    <div className="bp3-card">
                                        <p>{this.state.awareness1Dec}</p>
                                    </div>

                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                        <FileDownload
                                                awardType='GordSmith' 
                                                uploadPane="filesForAwareness1"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />
                                    </div>


                                    <ReviewerNotes 
                                        panel='AwarenessRising1'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                </div>: null
                                }

                                {/* if award level is gold level 2 or higher show form */}
                                {this.state.awardLevel === 'Gold Level 2' || this.state.awardLevel === 'Platinum' || this.state.awardLevel === 'Diamond' ?
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of activity </label>
                                        <div className="bp3-card">
                                            <p>{this.state.awareness2}</p>
                                        </div>

                                        <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                        <div className="bp3-card">
                                            <p>{this.state.awareness2Dec}</p>
                                        </div>

                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                            <FileDownload
                                                awardType='GordSmith' 
                                                uploadPane="filesForAwareness2"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />

                                        </div>

                                        <ReviewerNotes 
                                        panel='AwarenessRising2'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    </div> : null}
                            </div>

                            {/* ----------------------Educaiton or Skill Building---------------------- */}
                            <div className="bp3-card silver">
                                <h5>Education or Skill Building</h5>
                                {this.state.awardLevel=== 'Rising Star' ||
                                this.state.awardLevel === 'Bronze Level 1' || 
                                this.state.awardLevel === 'Bronze Level 2' || 
                                this.state.awardLevel === 'Silver Level 1' || 
                                this.state.awardLevel === 'Silver Level 2' ||
                                this.state.awardLevel === 'Gold Level 1' ||
                                this.state.awardLevel === 'Gold Level 2' ||
                                 this.state.awardLevel === 'Platinum' || 
                                 this.state.awardLevel === 'Diamond' ?
                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Name of activity </label>
                                    <div className="bp3-card">
                                        <p>{this.state.education1}</p>
                                    </div>

                                    <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                    <div className="bp3-card">
                                        <p>{this.state.education1Dec}</p>
                                    </div>

                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>

                                        <FileDownload
                                            awardType='GordSmith'
                                            uploadPane="filesForEducation1"
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid} />

                                    </div>
                                    <ReviewerNotes 
                                        panel='Education1'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                </div>:null
                                }

                                {/* if award level is gold level 2 or higher show form */}
                                {this.state.awardLevel === 'Gold Level 2' || this.state.awardLevel === 'Platinum' || this.state.awardLevel === 'Diamond' ?
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of activity </label>
                                        <div className="bp3-card">
                                            <p>{this.state.education2}</p>
                                        </div>

                                        <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                        <div className="bp3-card">
                                            <p>{this.state.education2Dec}</p>
                                            
                                        </div>

                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                            <FileDownload
                                                awardType='GordSmith'
                                                uploadPane="filesForEducation2"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />

                                        </div>
                                        <ReviewerNotes 
                                        panel='Education2'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    </div> : null}
                            </div>

                            {/* ----------------------Environmental Support---------------------- */}
                            <div className="bp3-card silver">
                                <h5>Environmental Support</h5>
                                {this.state.awardLevel=== 'Rising Star' ||
                                this.state.awardLevel === 'Bronze Level 1' || 
                                this.state.awardLevel === 'Bronze Level 2' || 
                                this.state.awardLevel === 'Silver Level 1' || 
                                this.state.awardLevel === 'Silver Level 2' ||
                                this.state.awardLevel === 'Gold Level 1' ||
                                this.state.awardLevel === 'Gold Level 2' ||
                                 this.state.awardLevel === 'Platinum' || 
                                 this.state.awardLevel === 'Diamond' ?
                                <div className="bp3-card bp3-elevation-2">
                                    <label className="bp3-label">Name of activity</label>
                                    <div className="bp3-card">
                                        <p>{this.state.environmental1}</p>
                                        
                                    </div>

                                    <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                    <div className="bp3-card">
                                        <p>{this.state.environmental1Dec}</p>
                                        
                                    </div>

                                    <div>
                                        <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                        <FileDownload
                                            awardType='GordSmith'
                                            uploadPane="filesForEnvironmental1"
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid} />

                                    </div>
                                    <ReviewerNotes 
                                        panel='Environmental1'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                </div>:null}

                                {/* if award level is gold level 2 or higher show form */}
                                {this.state.awardLevel === 'Gold Level 2' || this.state.awardLevel === 'Platinum' || this.state.awardLevel === 'Diamond' ?
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of activity</label>
                                        <div className="bp3-card">
                                            <p>{this.state.environmental2}</p>
                                        </div>

                                        <label style={{ marginTop: '18px' }} className="bp3-label">Description of how activity was conducted</label>
                                        <div className="bp3-card">
                                            <p>{this.state.environmental2Dec}</p>
                                        </div>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach evidence for this activity</label>
                                            <FileDownload
                                                awardType='GordSmith'
                                                uploadPane="filesForEnvironmental2"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />

                                        </div>
                                        <ReviewerNotes 
                                        panel='Environmental2'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    </div> : null}
                            </div>
                                {/*---------------Winners booklet for rising star------------------------ */}
                            {this.state.awardLevel === 'Rising Star' ?
                                <div>
                                    <div className="bp3-card silver">
                                        <h5>Statement of Employer’s Commitment to Employee Wellness</h5>
                                        <div className="bp3-card bp3-elevation-2">
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach Statement</label>
                                            <FileDownload
                                                awardType='GordSmith'
                                                uploadPane="statement"
                                                topicLevel={this.props.topicLevel}
                                                org={this.state.org.uid} />

                                        </div>
                                            
                                            <ReviewerNotes 
                                            panel='booklet'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                        </div>
                                    </div>
    
                                    <div className="bp3-card silver">
                                        <h5>Organization Highlight</h5>
                                        <div className="bp3-card bp3-elevation-2">
                                           <div className="bp3-card">
                                                <p>{this.state.winnersBooklet}</p>
                                            </div>
                                            
                                            <ReviewerNotes 
                                            panel='booklet'
                                            topicLevel={this.props.topicLevel}
                                            org={this.state.org.uid}
                                            />
                                        </div>
                                    </div>
                                </div>
                        
                            : null}

                            {/* ----------------------Supportive Policy Requirement---------------------- */}

                            {this.state.awardLevel === 'Bronze Level 1' || this.state.awardLevel === 'Bronze Level 2' || this.state.awardLevel === 'Silver Level 1' || this.state.awardLevel === 'Silver Level 2' || this.state.awardLevel === 'Gold Level 1' || this.state.awardLevel === 'Gold Level 2' || this.state.awardLevel === 'Platinum' || this.state.awardLevel === 'Diamond' ?
                                <div className="bp3-card silver">
                                    <h5>Supportive Policy Requirement</h5>
                                    <p>Provide {this.state.awardLevel === 'Bronze Level 1' || this.state.awardLevel === 'Bronze Level 2' || this.state.awardLevel === 'Silver Level 1' ||this.state.awardLevel === 'Silver Level 2' || this.state.awardLevel === 'Gold Level 1' || this.state.awardLevel === 'Gold Level 2' ? 'ONE' : 'TWO'} supportive policies specific to the wellness topic chosen</p>
                                    <div className="bp3-card bp3-elevation-2">
                                        <label className="bp3-label">Name of Policy</label>
                                        <div className="bp3-card">
                                            <p>{this.state.policy1}</p>
                                        </div>
                                        <div>
                                            <label style={{ marginTop: '18px' }} className="bp3-label">Attach Policy</label>
                                            <FileDownload
                                                    awardType='GordSmith'
                                                    uploadPane="filesForPolicy1"
                                                    topicLevel={this.props.topicLevel}
                                                    org={this.state.org.uid} />

                                        </div>
                                        <ReviewerNotes 
                                        panel='Policy1'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                    </div>
                                    {this.state.awardLevel === 'Platinum' || this.state.awardLevel === 'Diamond' ?
                                        <div className="bp3-card bp3-elevation-2">
                                            <label className="bp3-label">Name of Policy</label>
                                            <div className="bp3-card">
                                                <p>{this.state.policy2}</p>
                                            </div>
                                            <div>
                                                <label style={{ marginTop: '18px' }} className="bp3-label">Attach Policy</label>
                                                <FileDownload
                                                    awardType='GordSmith'
                                                    uploadPane="filesForPolicy2"
                                                    topicLevel={this.props.topicLevel}
                                                    org={this.state.org.uid} />

                                            </div>
                                            <ReviewerNotes 
                                        panel='Policy2'
                                        topicLevel={this.props.topicLevel}
                                        org={this.state.org.uid}
                                        />
                                        </div> : null}
                                </div> : null}
                    </TopContainer>
                </div>
            </div>
        );
    }
}

export default ReviewForm;
