import React from 'react';

import logos from '../assets/images/logos.png';

const Footer = (props) => {
    return (
        <div className="footer">
           <div className="footer-container">
             <p className="align-center">PRESENTED IN PARTNERSHIP BY</p>
             <div className="logos">
                 <img src={logos} alt="Gord Smith Healthy Workplace Awards" />
             </div>
           </div>
        </div>
    );
};

export default Footer;